import { useEffect, useState } from "react";

const SignButton = ({ title, validation, clicked }) => {
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [border, setBorder] = useState("");

  useEffect(() => {
    if (validation) {
      setBgColor("bg-darkBlueColor");
      setTextColor("text-white");
      setBorder("");
    } else {
      setBgColor("bg-white");
      setTextColor("text-black");
      setBorder("border-[1px] border-darkBlueColor");
    }
  }, [validation]);

  return (
    <div
      onClick={() => {
        validation && clicked();
      }}
      className={`flex items-center justify-center w-[290px] h-[41px] rounded-[4px] ${bgColor} ${border}`}
    >
      <p className={`text-[12px] font-SFProTextRegular ${textColor}`}>
        {title}
      </p>
    </div>
  );
};

export default SignButton;
