export const API_URL = process.env.NODE_ENV == "production" ? "https://vb25fxyfxvpsmzv5lyq74zo2uu0eamjb.lambda-url.us-east-1.on.aws" : "http://localhost:5000";
// export const API_URL = "http://192.168.132.70:5000";

export const bgColorArray = [
  { name: "red", value: "#B03060" },
  { name: "orange", value: "#FE9A76" },
  { name: "yellow", value: "#FFD700" },
  { name: "olive", value: "#32CD32" },
  { name: "green", value: "#016936" },
  { name: "teal", value: "#008080" },
  { name: "blue", value: "#0E6EB8" },
  { name: "violet", value: "#EE82EE" },
  { name: "purple", value: "#B413EC" },
  { name: "pink", value: "#FF1493" },
  { name: "grey", value: "#A0A0A0" },
  { name: "brown", value: "#A52A2A" },
  { name: "black", value: "#000000" },
  { name: "white", value: "#FFFFFF" },
];

export const fontColorArray = [
  { name: "black", value: "#000000" },
  { name: "white", value: "#FFFFFF" },
];

export const borderColorArray = [
  { name: "red", value: "#B03060" },
  { name: "orange", value: "#FE9A76" },
  { name: "yellow", value: "#FFD700" },
  { name: "olive", value: "#32CD32" },
  { name: "green", value: "#016936" },
  { name: "teal", value: "#008080" },
  { name: "blue", value: "#0E6EB8" },
  { name: "violet", value: "#EE82EE" },
  { name: "purple", value: "#B413EC" },
  { name: "pink", value: "#FF1493" },
  { name: "grey", value: "#A0A0A0" },
  { name: "brown", value: "#A52A2A" },
  { name: "black", value: "#000000" },
  { name: "white", value: "#FFFFFF" },
];

export const linkBlockTypeArray = [
  "External Link",
  "Internal Link",
  "Media Link (video/audio)",
  "External Image Link",
  "Internal Image Link",
  "Bio Link",
  "Slideshow Link",
];

export const socialNetworkArray = [
  "Facebook Icon",
  "Twitter Icon",
  "Twitter X Icon",
  "Facebook Messenger Icon",
  "Mail Icon",
  "Instagram Icon",
  "TikTok Icon",
  "Pinterest Icon",
  "WhatsApp Icon",
  "Amazon Icon",
  "YouTube Icon",
  "Etsy Icon",
  "Discord Icon",
  "Patreon Icon",
  "Reddit Icon",
  "Snapchat Icon",
  "Sound Cloud Icon",
  "Threads Icon",
  "Twitch Icon",
  "Apple Music Icon",
  "GitHub Icon",
];

export const promoCodeArray = ["FirstMonthFree", "OGFree", "ogfree"];

export const validImageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/tiff']
