import { useEffect, useState } from "react";
import { getImageUrl } from "src/common/function";

const NormalInput = ({
  title,
  type,
  valueChanged,
  value,
  errorMessage,
  placeholder,
}) => {
  const [border, setBorder] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      setBorder("border border-1 border-darkRedColor");
    } else {
      setBorder("");
    }

    if (errorMessage === "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [errorMessage]);

  return (
    <div className="w-[290px] h-[80px]">
      <p className="font-SFProTextSemiBold text-[16px] text-darkGray">
        {title}
      </p>
      <div
        className={`flex items-center w-full h-[33px] rounded-[10px] bg-inputBgColor px-3 mt-2 ${border}`}
      >
        <input
          type={type}
          value={value}
          onChange={(e) => {
            valueChanged(e.target.value);
          }}
          placeholder={placeholder}
          className="text-[17px] text-inputFontColor w-full bg-transparent font-SFProTextRegular"
        />
        {isValid && (
          <img
            src={getImageUrl("/images/authentication/check.png")}
            className="w-[25px] h-[25px]"
          />
        )}
      </div>
      <p className="font-SFProTextRegular text-[14px] text-darkRedColor">
        {errorMessage ? errorMessage : ""}
      </p>
    </div>
  );
};

export default NormalInput;
