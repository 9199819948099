import { useEffect, useState } from "react";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import LinkBlockPreviewPlaceholder from "../linkblockpreviewplaceholder/linkblockpreviewplaceholder";

const InternalImageLinkPreview = ({
  pBgColor,
  pFontColor,
  pBorderColor,
  pTitle,
  mainMediaImageUrl,
  internalMediaImageUrl,
  subtitle,
  internalLinkArray,
}) => {
  const [isMain, setIsMain] = useState(true);
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [borderColor, setBorderColor] = useState(null);

  useEffect(() => {
    setBgColor(pBgColor?.name);
  }, [pBgColor]);

  useEffect(() => {
    setFontColor(pFontColor?.name);
  }, [pFontColor]);

  useEffect(() => {
    setBorderColor(pBorderColor?.name);
  }, [pBorderColor]);

  return (
    <>
      {pTitle.length === 0 &&
      subtitle.length === 0 &&
      internalLinkArray.length === 0 &&
      !mainMediaImageUrl &&
      !internalMediaImageUrl ? (
        <LinkBlockPreviewPlaceholder />
      ) : (
        <div className="w-full">
          {isMain ? (
            <div className="flex items-center">
              <div
                className="w-full h-[193px] border-dashed border-[1px] border-[#4F4F4F] rounded-[12px] flex flex-col items-center gap-3 py-6 relative"
                style={{ backgroundColor: bgColor }}
              >
                <img src={mainMediaImageUrl} className="w-[158px] h-[110px] object-contain" alt="" />
                <p className="font-SFProTextRegular text-[14px] text-[#3F3F3F] font-[700]" style={{ color: fontColor }}>
                  {pTitle}
                </p>

                {/* border */}
                <div
                  className="absolute z-40 left-0 right-0 top-[-1px] rounded-tl-[12px] rounded-tr-[12px] h-[10px]"
                  style={{ backgroundColor: borderColor }}
                />
              </div>
              <MdOutlineArrowForwardIos size={25} onClick={() => setIsMain(false)} />
            </div>
          ) : (
            <div className="flex items-center">
              <MdOutlineArrowBackIos size={25} onClick={() => setIsMain(true)} />
              <div
                className="w-full h-[193px] border-dashed border-[1px] border-[#4F4F4F] rounded-[12px] flex items-center flex-col pt-[10px] pb-[10px]"
                style={{ backgroundColor: bgColor }}
              >
                <p className="font-SFProTextRegular text-[14px] text-[#3F3F3F] font-[700]" style={{ color: fontColor }}>
                  {pTitle}
                </p>
                <img src={internalMediaImageUrl} className="w-[158px] h-[110px] object-contain" alt="" />
                <p className="font-SFProTextRegular text-[14px] text-[#3F3F3F] font-[700] mt-[10px]" style={{ color: fontColor }}>
                  {subtitle}
                </p>
                <div className="flex flex-col items-center gap-[8px] mt-[10px]">
                  {internalLinkArray &&
                    internalLinkArray.map((item, index) => (
                      <div
                        className="w-[168px] h-[36px] bg-[#D9D9D9] rounded-[10px] flex items-center justify-center"
                        key={index}
                      >
                        <p className="font-SFProTextRegular text-[14px]  font-[400] ">{item.title}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InternalImageLinkPreview;
