import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { linkBlockTypeArray } from "src/common/constants";

const LinkBlockTypeSelect = ({ typeSelected, type }) => {
  const [selectedType, setSelectedType] = useState(type);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setSelectedType(type);
  }, [type]);

  const dropdownClicked = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const typeClicked = (index) => {
    typeSelected(index);
  };

  return (
    <div
      onClick={dropdownClicked}
      className="w-full h-[36px] bg-white flex items-center justify-between px-2 rounded-md relative bg-transparent z-50"
    >
      {selectedType ? (
        <p className="font-SFProTextRegular text-[14px] text-[#444A4A] font-[400]">{selectedType}</p>
      ) : (
        <p className="font-SFProTextRegular text-[14px] text-[#444A4A] font-[400]">Select Link Type</p>
      )}
      <FiChevronDown size={15} />
      {dropdownOpen && (
        <div className="w-full h-[180px] absolute top-[36px] left-0 bg-white overflow-auto rounded-sm">
          {linkBlockTypeArray.map((item, index) => (
            <div onClick={() => typeClicked(index)} className="w-full h-[25px] px-2" key={index}>
              <p className="font-SFProTextRegular text-[13px] text-[#444A4A] font-[400] ">{item}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LinkBlockTypeSelect;
