import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";

import { getImageUrl, handleError } from "src/common/function";
import NormalInput from "src/pages/auth/components/normalinput";
import SignButton from "src/pages/auth/components/signbutton";
import Footer from "src/pages/auth/components/footer";
import PageSwitch from "src/pages/auth/components/pageswitch";
import AuthService from "src/services/auth.service";
import TokenService from "src/services/token.service";
import { setAuthState } from "src/redux/slices/authslice";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email, password]);

  const goToSignupPage = () => {
    navigate("/signup");
  };

  const signin = () => {
    AuthService.signin(email, password)
      .then(function (response) {
        if (response.status == 200) {
          console.log("signin success: ", response);

          TokenService.updateLocalAccessToken(response.data.accessToken);
          TokenService.setUser(response.data.user);
          dispatch(setAuthState(true));
          navigate("/");
        }
      })
      .catch(function (error) {
        handleError(error);
      });
  };

  return (
    <div className="w-screen h-screen pt-[50px]">
      <div className="w-full h-[80px] pb-[20px] border-b-[1px] border-gray-400">
        <div className="w-full h-[60px] flex items-center justify-center relative">
          <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" alt="" />
          <div onClick={() => navigate("/landing")} className="absolute left-[20px] bottom-[-10px]">
            <MdKeyboardBackspace size={35} color="#3F3F3F" />
          </div>
        </div>
      </div>

      <div className="pl-5 mt-[40px] gap-6 relative">
        <NormalInput type="email" title="Email" value={email} valueChanged={(value) => setEmail(value)} />
        <div className="h-[30px]" />
        <NormalInput
          type="password"
          title="Password"
          value={password}
          valueChanged={(value) => setPassword(value)}
          className="mt-[20px]"
        />

        <div className="w-[290px] flex justify-end">
          <p
            onClick={() => navigate("/forgotpassword")}
            className=" font-SFProTextRegular text-[12px] text-[#EE0000] cursor-pointer text-right"
          >
            Forgot Password?
          </p>
        </div>
      </div>

      <div className="pl-5 mt-[100px]">
        <SignButton title="Sign In" validation={isValid} clicked={signin} />
        <PageSwitch clicked={goToSignupPage} title="or Create Account" />
      </div>

      <div className="absolute left-0 bottom-0 right-0">
        <Footer />
      </div>
    </div>
  );
};

export default SignIn;
