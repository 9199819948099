import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import _ from "lodash";
import CloseButton from "../../../components/closebutton";
import LinkBlockTypeSelect from "./linkblocktypeselect";
import TitleInput from "./titleinput";
import LinkBlockUrlInput from "./linkblockurlinput";
import UploadMedia from "./uploadmedia";
import ColorSelect from "../sociallinkmodal/colorselect";
import TokenService from "src/services/token.service";
import InternalAdditionalFields from "./internal/additionalfields/internaladditionalfields";
import ButtonGroup from "./buttongroup";
import { linkBlockTypeArray } from "src/common/constants";
import { bgColorArray, fontColorArray, borderColorArray } from "src/common/constants";
import ExternalLinkPreview from "./externallink/externallinkpreview";
import InternalLinkPreview from "./internal/internallinkpreview";
import ExternalImageLinkPreview from "./externalimagelink/externalimagelinkpreview";
import InternalImageLinkPreview from "./internalimagelink/internalimagelinkpreview";
import InternalImageAdditionalFields from "./internalimagelink/additionalfields/internalimageadditionalfields";
import BioAdditionalFields from "./biolink/additionalfields/bioadditionalfields";
import BioLinkPreview from "./biolink/biolinkpreview";
import MediaLinkPreview from "./medialink/medialinkpreview";
import SlideShowLinkPreview from "./slideshowlink/slideshowlinkpreview";
import SlideShowAdditionalFields from "./slideshowlink/additionalfields/slideshowadditionalfields";
import InvalidLinkMessage from "src/components/invalidlinkmessage";
import isUrlValid from "src/common/isUrlValid";

const LinkBlockModal = ({ shown, cancelClicked, editingLinkBlock, linkName, saveLinkBlock, updateLinkBlock }) => {
  console.log('LinkBlockModal-editingLinkBlock', editingLinkBlock)
  const [type, setType] = useState(null);
  const [title, setTitle] = useState("");
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [borderColor, setBorderColor] = useState(null);
  const [url, setUrl] = useState("");
  const [urlValid, setUrlValid] = useState(true);
  //aditional fields
  const [subTitle, setSubTitle] = useState("");
  const [internalLinkArray, setInternalLinkArray] = useState([]);
  //bio aditional fields
  const [bioContentArray, setBioContentArray] = useState([]);
  //Main Media Start
  const [mainMediaImageUrl, setmainMediaImageUrl] = useState(null);
  //Main Media End
  //Internal Media Start
  const [internalMediaImageUrl, setInternalMediaImageUrl] = useState(null);
  //Internal Media End
  const [slideshowSpeed, setSlideshowSpeed] = useState(3);
  const [slideshowImages, setSlideshowImages] = useState([]);

  const [mainMediaEnabled, setMainMediaEnabled] = useState(true);
  const [urlEnabled, setUrlEnabled] = useState(true);

  useEffect(() => {
    async function initializeData() {
      setType(null);
      setTitle("");
      setBgColor(bgColorArray.find((item) => item.name === "white"));
      setFontColor(fontColorArray.find((item) => item.name === "black"));
      setBorderColor(null);
      setUrl("");
      setSubTitle("");
      setInternalLinkArray([]);
      setBioContentArray([]);
      setmainMediaImageUrl(null);
      setInternalMediaImageUrl(null);
      setSlideshowSpeed(3);
      setSlideshowImages([]);

      if (editingLinkBlock) {
        setType(editingLinkBlock.type);
        setTitle(editingLinkBlock.title);
        setBgColor(bgColorArray.find((item) => item.name === editingLinkBlock.bgColor));
        setFontColor(fontColorArray.find((item) => item.name === editingLinkBlock.fontColor));
        setBorderColor(borderColorArray.find((item) => item.name === editingLinkBlock.borderColor));
        setUrl(editingLinkBlock.url);
        setSubTitle(editingLinkBlock.subTitle);
        setSlideshowSpeed(editingLinkBlock.slideshowSpeed);
        setmainMediaImageUrl(editingLinkBlock.mainMediaImageUrl);
        setInternalMediaImageUrl(editingLinkBlock.internalMediaImageUrl)

        if (editingLinkBlock.internalLinkArray.length > 0) {
          let tempInternalLinkArray = JSON.parse(editingLinkBlock.internalLinkArray);
          for (let i = 0; i < tempInternalLinkArray.length; i++) {
            tempInternalLinkArray[i].isExpanded = false;
          }
          setInternalLinkArray(tempInternalLinkArray);
        }

        if (editingLinkBlock.bioContentArray.length > 0) {
          let tempBioContentArray = JSON.parse(editingLinkBlock.bioContentArray);
          for (let i = 0; i < tempBioContentArray.length; i++) {
            tempBioContentArray[i].isExpanded = false;
          }
          setBioContentArray(tempBioContentArray);
        }

        let tempSlideshowImages = JSON.parse(editingLinkBlock.slideshowImages);
        setSlideshowImages(tempSlideshowImages);
      }
    }

    initializeData();
  }, [shown, editingLinkBlock]);

  useEffect(() => {
    switch (type) {
      case 0: //external link
        setMainMediaEnabled(false);
        setUrlEnabled(true);
        break;
      case 1: //internal link
        setMainMediaEnabled(true);
        setUrlEnabled(false);
        break;
      case 2: //main media
        setMainMediaEnabled(false);
        setUrlEnabled(true);
        break;
      case 3: //external image link
        setMainMediaEnabled(true);
        setUrlEnabled(true);
        break;
      case 4: //internal image link
        setMainMediaEnabled(true);
        setUrlEnabled(false);
        break;
      case 5: //bio  link
        setMainMediaEnabled(false);
        setUrlEnabled(false);
        break;
      case 6: //slideshow
        setMainMediaEnabled(false);
        setUrlEnabled(false);
        break;
      default:
        break;
    }

    setUrlValid(true);
    setTitle("");
    setBgColor(bgColorArray.find((item) => item.name === "white"));
    setFontColor(fontColorArray.find((item) => item.name === "black"));
    setBorderColor(null);
    setUrl("");
    setSubTitle("");
    setInternalLinkArray([]);
    setBioContentArray([]);
    setmainMediaImageUrl(null);
    setInternalMediaImageUrl(null);
    setSlideshowSpeed(3);
    setSlideshowImages([]);
  }, [type]);

  const linkBlockTypeChanged = (type) => {
    setType(type);
  };

  const mediaSelected = (imageUrl) => {
    setmainMediaImageUrl(imageUrl);
  };

  const internalMediaSelected = (imageUrl) => {
    setInternalMediaImageUrl(imageUrl);
  };

  const handleSlideshowSpeedChange = (value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setSlideshowSpeed(value);
    }
  };

  const save = () => {
    if (!type)
      for (let i = 0; i < internalLinkArray.length; i++) {
        if (internalLinkArray[i].hasOwnProperty("isExpanded")) {
          delete internalLinkArray[i]["isExpanded"];
        }
      }

    const currentUser = TokenService.getUser();

    let slideshowImageArray = slideshowImages.filter((item) => item.type > -1);

    if (editingLinkBlock) {
      const data = {
        mainMediaImageUrl: mainMediaImageUrl,
        internalMediaImageUrl: internalMediaImageUrl,
        type,
        title,
        bgColor: bgColor?.name,
        fontColor: fontColor?.name,
        borderColor: borderColor?.name,
        url,
        userId: currentUser.id,
        subTitle: subTitle,
        internalLinkArray: JSON.stringify(internalLinkArray),
        bioContentArray: JSON.stringify(bioContentArray),
        slideshowImages: JSON.stringify(slideshowImageArray),
        slideshowSpeed,
      };

      if (editingLinkBlock._id) {
        data._id = editingLinkBlock._id;
      }
      updateLinkBlock(data);
    } else {
      const data = {
        mainMediaImageUrl,
        internalMediaImageUrl,
        type,
        title,
        bgColor: bgColor?.name,
        fontColor: fontColor?.name,
        borderColor: borderColor?.name,
        url,
        userId: currentUser.id,
        subTitle: subTitle,
        internalLinkArray: JSON.stringify(internalLinkArray),
        bioContentArray: JSON.stringify(bioContentArray),
        linkName: linkName,
        slideshowImages: JSON.stringify(slideshowImageArray),
        slideshowSpeed,
        created: new Date(),
      };

      saveLinkBlock(data);
    }
  };

  // Internal link for additional fields START
  const addInternalLinks = () => {
    let temp = _.cloneDeep(internalLinkArray);
    temp.push({ title: "", link: "", isExpanded: false });
    setInternalLinkArray(temp);
  };

  const internalLinkTitleChanged = (title, index) => {
    let temp = _.cloneDeep(internalLinkArray);
    temp[index].title = title;
    setInternalLinkArray(temp);
  };

  const internalLinkLinkChanged = (link, index) => {
    let temp = _.cloneDeep(internalLinkArray);
    temp[index].link = link;
    setInternalLinkArray(temp);
  };

  const internalLinkClicked = (index) => {
    let temp = _.cloneDeep(internalLinkArray);
    temp[index].isExpanded = !temp[index].isExpanded;
    setInternalLinkArray(temp);
  };
  // Internal link for additional fields END

  //Bio Link Start
  const addBioContent = () => {
    let temp = _.cloneDeep(bioContentArray);
    temp.push({ header: "", body: "", isExpanded: false });
    setBioContentArray(temp);
  };

  const bioHeaderChanged = (header, index) => {
    let temp = _.cloneDeep(bioContentArray);
    temp[index].header = header;
    setBioContentArray(temp);
  };

  const bioBodyChanged = (body, index) => {
    let temp = _.cloneDeep(bioContentArray);
    temp[index].body = body;
    setBioContentArray(temp);
  };

  const bioContentClicked = (index) => {
    let temp = _.cloneDeep(bioContentArray);
    temp[index].isExpanded = !temp[index].isExpanded;
    setBioContentArray(temp);
  };
  //Bio Link End

  //Slideshow Start

  const addSlideshowImage = () => {
    let temp = _.cloneDeep(slideshowImages);
    temp.push({ type: -1, fileName: "", imageUrl: null });
    setSlideshowImages(temp);
  };

  const slideshowFileSelected = (type, fileName, imageUrl, index, inputFieldVal) => {
    let temp = _.cloneDeep(slideshowImages);
    temp[index].type = type;
    if (type === 0) {
      temp[index].imageUrl = inputFieldVal;
    } else if (type === 1) {
      temp[index].fileName = fileName;
      temp[index].imageUrl = imageUrl;
    }

    setSlideshowImages(temp);
  };
  //Slideshow End

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="bg-[#F2F6F9] w-[300px] h-[610px] pb-5 rounded-md flex flex-col">
            <div className="a w-full h-[44px] flex items-center justify-between bg-white rounded-t-md px-[20px]">
              <p className="font-SFProTextHeavy text-[20px] text-[#4F4F4F] font-[800]">{"Create > Link Block"}</p>
              <CloseButton clicked={cancelClicked} />
            </div>
            <div className="w-full h-full overflow-auto">
              <div className="w-full pl-[10px] pr-[15px]">
                <div className="w-full">
                  <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">Preview</p>
                  {type === 0 ? (
                    <ExternalLinkPreview pBgColor={bgColor} pFontColor={fontColor} pBorderColor={borderColor} pTitle={title} />
                  ) : type === 1 ? (
                    <InternalLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      mainMediaImageUrl={mainMediaImageUrl}
                      subtitle={subTitle}
                      internalLinkArray={internalLinkArray}
                    />
                  ) : type === 2 ? (
                    <MediaLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      url={url}
                    />
                  ) : type === 3 ? (
                    <ExternalImageLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      imageUrl={mainMediaImageUrl}
                    />
                  ) : type === 4 ? (
                    <InternalImageLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      mainMediaImageUrl={mainMediaImageUrl}
                      subtitle={subTitle}
                      internalLinkArray={internalLinkArray}
                      internalMediaImageUrl={internalMediaImageUrl}
                    />
                  ) : type === 5 ? (
                    <BioLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      bioContentArray={bioContentArray}
                    />
                  ) : type === 6 ? (
                    <SlideShowLinkPreview
                      pBgColor={bgColor}
                      pFontColor={fontColor}
                      pBorderColor={borderColor}
                      pTitle={title}
                      images={slideshowImages}
                      slideshowSpeed={slideshowSpeed}
                    />
                  ) : null}
                </div>
                <div className="w-full">
                  <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">
                    Link Block Type
                  </p>
                  <LinkBlockTypeSelect typeSelected={linkBlockTypeChanged} type={linkBlockTypeArray[type]} />
                </div>
                {type !== null && (
                  <>
                    <TitleInput text={title} textChanged={(text) => setTitle(text)} />
                    <LinkBlockUrlInput
                      text={url}
                      textChanged={(text) => {
                        setUrl(text);
                        if (text.length > 0 && urlEnabled) {
                          setUrlValid(isUrlValid(url));
                        } else {
                          setUrlValid(true);
                        }
                      }}
                      enabled={urlEnabled}
                    />
                    <InvalidLinkMessage shown={!urlValid} />
                    <UploadMedia
                      label="Add Media"
                      mediaSelected={mediaSelected}
                      defaultImage={mainMediaImageUrl}
                      enabled={mainMediaEnabled}
                      linkName={linkName}
                    />
                    <div className="w-full">
                      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">
                        Background Color
                      </p>
                      <ColorSelect
                        colorArray={bgColorArray}
                        colorSelected={(color) => setBgColor(color)}
                        color={bgColor}
                        defaultValue="Select Link Block Background Color"
                      />
                    </div>
                    <div className="w-full">
                      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">
                        Font Color
                      </p>
                      <ColorSelect
                        colorArray={fontColorArray}
                        colorSelected={(color) => setFontColor(color)}
                        color={fontColor}
                        defaultValue="Select Link Block Font Color"
                      />
                    </div>
                    <div className="w-full">
                      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">
                        Border Color
                      </p>
                      <ColorSelect
                        colorArray={borderColorArray}
                        colorSelected={(color) => setBorderColor(color)}
                        color={borderColor}
                        defaultValue="Select Link Block Border Color"
                      />
                    </div>
                  </>
                )}
              </div>

              {type === 1 && (
                <div className="mt-[20px]">
                  <div className="w-full h-[1px] bg-gray-400" />
                  <div className="w-full pl-[10px] pr-[15px] mt-2">
                    <InternalAdditionalFields
                      subtitle={subTitle}
                      subtitleChanged={(text) => setSubTitle(text)}
                      internalLinks={internalLinkArray}
                      addInternalLinks={addInternalLinks}
                      titleChanged={internalLinkTitleChanged}
                      linkChanged={internalLinkLinkChanged}
                      linkClicked={internalLinkClicked}
                    />
                  </div>
                </div>
              )}
              {type === 4 && (
                <div className="mt-[20px]">
                  <div className="w-full h-[1px] bg-gray-400" />
                  <div className="w-full pl-[10px] pr-[15px] mt-2">
                    <InternalImageAdditionalFields
                      subtitle={subTitle}
                      subtitleChanged={(text) => setSubTitle(text)}
                      internalLinks={internalLinkArray}
                      addInternalLinks={addInternalLinks}
                      titleChanged={internalLinkTitleChanged}
                      linkChanged={internalLinkLinkChanged}
                      linkClicked={internalLinkClicked}
                      mediaSelected={internalMediaSelected}
                      internalMediaImageUrl={internalMediaImageUrl}
                      linkName={linkName}
                    />
                  </div>
                </div>
              )}

              {type === 5 && (
                <div className="mt-[20px]">
                  <div className="w-full h-[1px] bg-gray-400" />
                  <div className="w-full pl-[10px] pr-[15px] mt-2">
                    <BioAdditionalFields
                      bioContents={bioContentArray}
                      addBioContent={addBioContent}
                      headerChanged={bioHeaderChanged}
                      bodyChanged={bioBodyChanged}
                      itemClicked={bioContentClicked}
                    />
                  </div>
                </div>
              )}

              {type === 6 && (
                <div className="mt-[20px]">
                  <div className="w-full h-[1px] bg-gray-400" />
                  <div className="w-full pl-[10px] pr-[15px] mt-2">
                    <SlideShowAdditionalFields
                      slideshowSpeed={slideshowSpeed}
                      slideshowSpeedChanged={handleSlideshowSpeedChange}
                      slideshowImages={slideshowImages}
                      addSlideshowImage={addSlideshowImage}
                      fileSelected={slideshowFileSelected}
                      linkName={linkName}
                    />
                  </div>
                </div>
              )}

              {type !== null && (
                <div className="w-full h-[40px] flex gap-5 mt-[48px] pl-[10px] pr-[15px]">
                  <ButtonGroup save={save} cancel={cancelClicked} />
                </div>
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default LinkBlockModal;
