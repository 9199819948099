import { FaFacebookF } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaAmazon } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BiLogoEtsy } from "react-icons/bi";
import { FaDiscord } from "react-icons/fa";
import { FaPatreon } from "react-icons/fa";
import { FaRedditAlien } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import { FaSoundcloud } from "react-icons/fa";
import { FaThreads } from "react-icons/fa6";
import { FaTwitch } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const size = 42;

const SocialLinkLogo = ({ socialName }) => {
  return (
    <>
      {socialName == "Facebook Icon" ? (
        <FaFacebookF size={size} />
      ) : socialName === "Twitter Icon" ? (
        <FaTwitter size={size} />
      ) : socialName === "Twitter X Icon" ? (
        <FaXTwitter size={size} />
      ) : socialName === "Facebook Messenger Icon" ? (
        <FaFacebookMessenger size={size} />
      ) : socialName === "Mail Icon" ? (
        <AiOutlineMail size={size} />
      ) : socialName === "Instagram Icon" ? (
        <FaInstagram size={size} />
      ) : socialName === "TikTok Icon" ? (
        <FaTiktok size={size} />
      ) : socialName === "Pinterest Icon" ? (
        <FaPinterestP size={size} />
      ) : socialName === "WhatsApp Icon" ? (
        <FaWhatsapp size={size} />
      ) : socialName === "Amazon Icon" ? (
        <FaAmazon size={size} />
      ) : socialName === "YouTube Icon" ? (
        <FaYoutube size={size} />
      ) : socialName === "Etsy Icon" ? (
        <BiLogoEtsy size={size} />
      ) : socialName === "Discord Icon" ? (
        <FaDiscord size={size} />
      ) : socialName === "Patreon Icon" ? (
        <FaPatreon size={size} />
      ) : socialName === "Reddit Icon" ? (
        <FaRedditAlien size={size} />
      ) : socialName === "Snapchat Icon" ? (
        <FaSnapchatGhost size={size} />
      ) : socialName === "Sound Cloud Icon" ? (
        <FaSoundcloud size={size} />
      ) : socialName === "Threads Icon" ? (
        <FaThreads size={size} />
      ) : socialName === "Twitch Icon" ? (
        <FaTwitch size={size} />
      ) : socialName === "Apple Music Icon" ? (
        <SiApplemusic size={size} />
      ) : socialName === "GitHub Icon" ? (
        <FaGithub size={size} />
      ) : null}
    </>
  );
};

export default SocialLinkLogo;
