import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getImageUrl } from "src/common/function";
import SigninButton from "./components/signinbutton";
import FourDots from "./components/fourdots";

const titleArray = [
  "Wecome to <br> The Right Link",
  "Unlock Your Digital Presence",
  "Link Blocks for Every Need",
  "Simple & Affordable",
];
const subTitleArray = [
  "Your premier Link-in-Bio management portal! Easily create and manage your  personalized micro-website, we call this process “Unlocking your digital presence with one Right-Link”.",
  "Transform your online identity with Right-Link. Seamlessly organize your “Social Links” using stylish icons, making it easier for your audience to connect with you. Our intuitive interface lets you manage and update your Right-Links effortlessly, ensuring that your digital presence stays vibrant and engaging.",
  "With our versatile link blocks, you can share external links, direct visitors to your latest blog post, or even display rich media content directly on your Right-Link. The possibilities are endless, giving you the flexibility to curate a dynamic and engaging experience for your audience.",
  "Managing your right-links is completely free! Take your time to perfect the layout and content. When you’re ready to unveil your Right-Link to the world, our subscription plan is priced at $2.50/month. It’s an affordable solution to enhance your online presence and make a lasting impression. Get started today and level up your online presence!",
];

const titleFontSizeArray = ["text-[42px]", "text-[26px] mt-[25px]", "text-[26px] mt-[25px]", "text-[26px] mt-[25px]"];

const Landing = () => {
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState(0);

  const nextClicked = () => {
    if (pageIndex >= 3) {
      setPageIndex(0);
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  return (
    <div className="w-screen h-screen pt-[50px] flex flex-col">
      <div className="w-full h-[80px] pb-[20px] border-b-[1px] border-gray-400">
        <div className="w-full h-[60px] flex items-center justify-center relative">
          <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" />
          <div className="absolute right-[20px] bottom-0">
            <SigninButton clicked={() => navigate("/signin")} />
          </div>
        </div>
      </div>
      <div className="w-full h-full mt-[20px]">
        <div
          dangerouslySetInnerHTML={{ __html: titleArray[pageIndex] }}
          className={`font-roboto-mono text-darkGray font-bold text-center leading-[120%] ${titleFontSizeArray[pageIndex]}`}
        />
        <div
          dangerouslySetInnerHTML={{ __html: subTitleArray[pageIndex] }}
          className="font-roboto-mono text-[20px] text-darkGray text-center mt-[20px] px-2"
        />
        <img
          src={getImageUrl(`/images/landing/landing_${pageIndex}.png`)}
          className="w-[270px] h-[270px] object-contain ml-auto mr-auto mt-[40px]"
        />
        <div className="w-full flex justify-center mt-[15px]">
          <FourDots pageIndex={pageIndex} />
        </div>

        <div className="absolute w-full left-0 bottom-0 px-[20px] pb-[20px] flex items-center justify-between">
          <p
            onClick={() => navigate("/signin")}
            className="font-roboto-mono text-[24px] text-darkBlueColor font-medium cursor-pointer"
          >
            Skip
          </p>
          <div
            onClick={nextClicked}
            className="w-[85px] h-[31px] rounded-md bg-darkBlueColor flex items-center justify-center cursor-pointer"
          >
            <p className="font-SFProTextRegular text-[11px] text-white">Next</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
