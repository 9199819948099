import { getImageUrl } from "src/common/function";

const LinkBlockPlaceholder = () => {
  return (
    <div className="w-full h-[106px] border-dashed border-gray-500 border-[1px] bg-[#F2F6F9] rounded-[12px] flex items-center">
      <div className="h-[40px] pl-[15px] flex items-center gap-2">
        <img src={getImageUrl("/images/avatar.png")} className="h-[50px] object-fill" />
        <div className="flex flex-col">
          <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700]">Title</p>
          <p className="font-SFProTextRegular text-[14px] text-[#4F4F4F] font-[400]">Enter Link URL</p>
        </div>
      </div>
    </div>
  );
};

export default LinkBlockPlaceholder;
