import api from "./api";

const saveLinkBlock = (data) => {
  return api.post("/api/linkblock/save", data);
};

// const updateLinkBlock = (data) => {
//   return api.post("/api/linkblock/update", data);
// };

const getLinkData = (data) => {
  return api.get("/api/link/get_linkdata", { params: data });
};

const LinkBlockService = {
  saveLinkBlock,
  // updateLinkBlock,
  getLinkData,
};

export default LinkBlockService;
