import { getImageUrl } from "src/common/function";

const CloseButton = ({ clicked }) => {
  return (
    <div
      onClick={clicked}
      className="w-[30px] h-[30px] rounded-[15px] bg-[#F2F6F9] flex items-center justify-center cursor-pointer"
    >
      <img src={getImageUrl("/images/close_icon.png")} className="w-[16px] h-[16px]" />
    </div>
  );
};

export default CloseButton;
