import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getImageUrl } from "src/common/function";

const FourDots = ({ pageIndex }) => {
  const [bgColorArray, setBgColorArray] = useState([
    "bg-darkBlueInactive",
    "bg-darkBlueInactive",
    "bg-darkBlueInactive",
    "bg-darkBlueInactive",
  ]);

  useEffect(() => {
    let tempColorArray = ["bg-darkBlueInactive", "bg-darkBlueInactive", "bg-darkBlueInactive", "bg-darkBlueInactive"];
    tempColorArray[pageIndex] = "bg-darkBlueColor";
    setBgColorArray(tempColorArray);
  }, [pageIndex]);

  return (
    <div className="w-[130px] flex gap-2 justify-center">
      <div className={`w-[18px] h-[18px] rounded-[9px] ${bgColorArray[0]}`} />
      <div className={`w-[18px] h-[18px] rounded-[9px] ${bgColorArray[1]}`} />
      <div className={`w-[18px] h-[18px] rounded-[9px] ${bgColorArray[2]}`} />
      <div className={`w-[18px] h-[18px] rounded-[9px] ${bgColorArray[3]}`} />
    </div>
  );
};

export default FourDots;
