import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from './routes/UnAuthenticatedRoutes';
// import Checkout from './components/checkout';

const AppContainer = () => {
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);

    // const initialOptions = {
    //     "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    //     currency: "USD",
    //     vault: true,
    //     intent: "subscription",    
    // }

    // remove for production
    useEffect(() => {
      console.log("isAuthenticated: ", isAuthenticated);
    }, [isAuthenticated]);

    return (
        <div>
        <ToastContainer />
        {/* <PayPalScriptProvider options={initialOptions}>
            <Checkout/>
        </PayPalScriptProvider> */}
        {
            isAuthenticated ? (
                <AuthenticatedRoutes isAuthenticated={isAuthenticated} />
            ) : (
                <UnauthenticatedRoutes />
            )
        }
        </div>
    )
}

export default AppContainer;
