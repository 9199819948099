import { Route, Routes, Navigate } from 'react-router-dom'
import Home from "src/pages/home/home";
import Main from "src/pages/main/main";

const AuthenticatedRoutes = ({isAuthenticated}) => {
    const PrivateRoute = ({ auth, children }) => {
        return auth ? children : <Navigate to="/landing" />;
      };

    return (
        <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute auth={isAuthenticated}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage/:paramLinkName"
          element={
            <PrivateRoute auth={isAuthenticated}>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage"
          element={
            <PrivateRoute auth={isAuthenticated}>
              <Main />
            </PrivateRoute>
          }
        />
      </Routes>
    )
}

export default AuthenticatedRoutes;
