import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SketchPicker } from "react-color";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import _ from "lodash";
import { getImageUrl, handleError } from "src/common/function";
import Footer from "src/components/footer";
import AvatarModal from "./components/avatarmodal";
import TokenService from "src/services/token.service";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import SocialLinkModal from "./sociallinkmodal/sociallinkmodal";
import AddSocialLinkButton from "./components/addsociallinkbutton";
import CreateButton from "./components/createbutton";
import CreateModal from "./createmodal/createmodal";
import LinkBlockModal from "src/pages/main/linkblockmodal/linkblockmodal";
import LinkBlockPlaceholder from "src/pages/main/components/linkblock/linkblockplaceholder";
import LinkBlock from "./components/linkblock/linkblock";
import SaveButton from "src/components/savebutton";
import LinkBlockService from "src/services/linkblock.service";
import LinkService from "src/services/link.service";
import SocialLinkLogo from "src/components/sociallinklogo";
import SocialIconEditButton from "src/components/socialiconeditbutton";
import SideMenu from "src/components/sidemenu";
import SubscriptionModal from "src/components/subscriptionmodal";
import PublishButton from "src/components/publishbutton";
import PublishModal from "src/components/publishmodal";
import ActiveSubscriptionActions from "src/components/activeSubscriptionActions";
import InactiveSubscriptionActions from "src/components/inactiveSubscriptionActions";

const domain = "https://right-link.com/";

const Main = () => {
  let { paramLinkName } = useParams();

  const currentUser = TokenService.getUser();
  // Link Information Start
  // const [linkId, setLinkId] = useState(null);
  const [linkName, setLinkName] = useState("");
  const [linkblockArray, setLinkblockArray] = useState([]);
  const [debouncedLinkName] = useDebounce(linkName, 500);
  const [linkTitle, setLinkTitle] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [socialLinkPosition, setSocialLinkPosition] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);
  const [subscribed, setSubscribed] = useState(false);
  const [published, setPublished] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  //Link Information End
  const [linkNameValid, setLinkNameValid] = useState(false);
  const [linkNameConfirmed, setLinkNameConfirmed] = useState(false);
  const [avatarModalShown, setAvatarModalShown] = useState(false);
  const [socialLinkModalShown, setSocialLinkModalShown] = useState(false);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [linkBlockModalShown, setLinkBlockModalShown] = useState(false);
  const [paletteShown, setPaletteShown] = useState(false);
  const [disablePalette, setDisablePalette] = useState("pointer-events-none");
  const [titleInputClass, setTitleInputClass] = useState("border-b border-gray-500");
  const [titleWrapperClass, setTitleWrapperClass] = useState("bg-[#F2F6F9]");
  const [editingLinkBlock, setEditingLinkBlock] = useState(null);
  const [editingLinkBlockIndex, setEditingLinkBlockIndex] = useState(null);
  const [editingSocialLinkIndex, setEditingSocialLinkIndex] = useState(0);
  const [sideMenuShown, setSideMenuShown] = useState(false);
  const [subscriptionModalShown, setSubscriptionModalShown] = useState(false);
  const [publishModalShown, setPublishModalShown] = useState(false);
  //tab
  const [tabIndex, setTabIndex] = useState(0);
  const [tabBgColor, setTabBgColor] = useState(["bg-darkBlueColor", "bg-white"]);
  const [tabFontColor, setTabFontColor] = useState(["text-white", "text-darkBlueColor"]);
  const [color, setColor] = useState("#FFFFFF");
  const [addSocialLinkButtonShown, setAddSocialLinkButtonShown] = useState(true);

  useEffect(() => {
    setLinkNameConfirmed(false);
    setLinkName("");
    setLinkblockArray([]);
    setLinkTitle("");
    setAvatarUrl(null);
    setBgColor(null);
    setSocialLinks([]);
    setSubscribed(false);
    setPublished(false);
    setPromoCode(null);

    if (paramLinkName) {
      setLinkName(paramLinkName);
      setLinkNameConfirmed(true);
    }
  }, [paramLinkName]);

  useEffect(() => {
    if (linkNameConfirmed) {
      getAllLinks();
    }
  }, [linkNameConfirmed]);

  const getAllLinks = () => {
    LinkBlockService.getLinkData({ userId: currentUser.id, linkName: linkName })
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.link) {
            setLinkName(response.data.link.name);
            setLinkTitle(response.data.link.title);
            if (response.data.link.color) setColor(response.data.link.color);
            setBgColor(response.data.link.color);
            setSocialLinkPosition(response.data.link.socialLinkPosition);
            setSubscribed(response.data.link.subscribed);
            setPublished(response.data.link.published);
            setPromoCode(response.data.link.promoCode);
            setAvatarUrl(response.data.link.avatarUrl)
          }
          if (response.data.linkblocks) {
            let linkblocks = response.data.linkblocks;
            let temp = [];
            for (let i = 0; i < linkblocks.length; i++) {
              if (linkblocks[i].type === 6) {
                let slideshowImages = [];
                for (let image of JSON.parse(linkblocks[i].slideshowImages)) {
                  slideshowImages.push({ ...image, imageUrl: image.imageUrl });
                }
                linkblocks[i].slideshowImages = JSON.stringify(slideshowImages);
              }

              temp.push(linkblocks[i]);
            }
            setLinkblockArray(linkblocks);
          }

          if (response.data.sociallinks) {
            setSocialLinks(response.data.sociallinks);
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (subscribed) {
      saveLink(false);
    }
  }, [subscribed]);

  useEffect(() => {
    if (published) {
      saveLink(false);
    }
  }, [published]);

  useEffect(() => {
    if (debouncedLinkName.length === 0) {
      setLinkNameValid(false);
      return;
    }
    LinkService.checkLinkValidation({ name: debouncedLinkName })
      .then((response) => {
        // console.log("Uniqueness: ", response);
        if (response.status === 200) {
          setLinkNameValid(response.data.result);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [debouncedLinkName]);

  const avatarSelected = (uploadedImgUrl) => {
    setAvatarUrl(uploadedImgUrl);
    setAvatarModalShown(false);
  };

  const paletteClicked = () => {
    if (!paletteShown) {
      setPaletteShown(true);
    }
  };

  useEffect(() => {
    if (paletteShown) {
      setDisablePalette("pointer-events-none");
    } else {
      setDisablePalette("");
    }
  }, [paletteShown]);

  useEffect(() => {
    if (tabIndex === 0) {
      setTabBgColor(["bg-darkBlueColor", "bg-white"]);
      setTabFontColor(["text-white", "text-darkBlueColor"]);
    } else {
      setTabBgColor(["bg-white", "bg-darkBlueColor"]);
      setTabFontColor(["text-darkBlueColor", "text-white"]);
    }
  }, [tabIndex]);

  const handleTitleFocus = () => {
    setTitleInputClass("border-b border-gray-500");
    setTitleWrapperClass("bg-[#F2F6F9]");
  };

  const handleTitleBlur = () => {
    setTitleInputClass("");
    setTitleWrapperClass("");
  };

  const editLink = (index) => {
    setEditingLinkBlock(linkblockArray[index]);
    setEditingLinkBlockIndex(index);
    setLinkBlockModalShown(true);
  };

  const confirmLinkName = () => {
    setLinkNameConfirmed(true);
    saveLink(false);
  };

  const saveLinkBlock = (data) => {
    setLinkBlockModalShown(false);
    let temp = _.cloneDeep(linkblockArray);
    temp.unshift(data);
    setLinkblockArray(temp);
  };

  const updateLinkBlock = (data) => {
    setLinkBlockModalShown(false);

    let temp = _.cloneDeep(linkblockArray);
    temp[editingLinkBlockIndex] = data;
    setLinkblockArray(temp);
  };

  const saveLink = (messageShown = true) => {
    const data = {
      link: {
        name: linkName,
        title: linkTitle,
        userId: currentUser.id,
        color: bgColor,
        avatarUrl: avatarUrl,
        socialLinkPosition: socialLinkPosition,
        subscribed: subscribed,
        published: published,
        promoCode: promoCode,
      },
      linkblocks: linkblockArray,
      sociallinks: socialLinks,
    };

    LinkService.saveLink(data)
      .then(async (response) => {
        if (response.status === 200) {
          getAllLinks();
          messageShown && toast("Link saved successfully");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const socialIconEditClicked = (index) => {
    setEditingSocialLinkIndex(index);
    setSocialLinkModalShown(true);
  };

  const handlePublish = async (messageShown = true) => {
    const data = {
      userId: currentUser.id,
      linkName: linkName
    }

    await LinkService.publishLink(data)
      .then(async (response) => {
        console.log('resres', response)
        if (response.status === 200) {
          getAllLinks();
          messageShown && toast("Link published successfully");
        }
      })
      .catch((error) => {
        console.log('error', error)
        handleError(error);
      });

    setPublishModalShown(true);
    setPublished(true);
  };

  const handleLinkNameChange = (e) => {
    const regex = /^[a-zA-Z0-9-_]*$/;
    const value = e.target.value;
    if (regex.test(value) || value === "") {
      setLinkName(value);
    }
  };

  const handleCopy = (linkToCopy) => {
    navigator.clipboard.writeText(linkToCopy)
  };

  return (
    <div className="w-screen h-screen pt-[50px] flex flex-col pb-[89px]">
      <AvatarModal shown={avatarModalShown} cancelClicked={() => setAvatarModalShown(false)} avatarSelected={avatarSelected} linkName={linkName}/>
      <CreateModal
        socialLinkModalClicked={() => {
          setSocialLinkModalShown(true);
          setCreateModalShown(false);
        }}
        linkBlockModalClicked={() => {
          setEditingLinkBlock(null);
          setEditingLinkBlockIndex(null);
          setLinkBlockModalShown(true);
          setCreateModalShown(false);
        }}
        cancelClicked={() => setCreateModalShown(false)}
        shown={createModalShown}
      />
      <SocialLinkModal
        shown={socialLinkModalShown}
        data={socialLinks}
        saveClicked={(data, position) => {
          setSocialLinks(data);
          setSocialLinkPosition(position);
          setSocialLinkModalShown(false);
          if (data.length >= 5) {
            setAddSocialLinkButtonShown(false);
          }
        }}
        editingSocialLinkIndex={editingSocialLinkIndex}
        pPosition={socialLinkPosition}
        cancelClicked={() => setSocialLinkModalShown(false)}
      />
      <LinkBlockModal
        cancelClicked={() => {
          setLinkBlockModalShown(false);
        }}
        saveLinkBlock={saveLinkBlock}
        updateLinkBlock={updateLinkBlock}
        linkName={linkName}
        shown={linkBlockModalShown}
        editingLinkBlock={editingLinkBlock}
      />
      <SideMenu shown={sideMenuShown} onClose={() => setSideMenuShown(false)} />
      <SubscriptionModal
        shown={subscriptionModalShown}
        cancelClicked={() => setSubscriptionModalShown(false)}
        subscribeSuccess={(promo) => {
          setSubscribed(true);
          if (promo) setPromoCode(promo);
        }}
      />
      <PublishModal shown={publishModalShown} cancelClicked={() => setPublishModalShown(false)} />
      {/* Header Start */}
      <div className="w-full h-[60px] flex items-center justify-center relative">
        <img
          onClick={() => setSideMenuShown(true)}
          src={getImageUrl("/images/menu_button.png")}
          className="absolute left-5 bottom-3 h-[20px] cursor-pointer"
        />
        <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" />
        {linkNameConfirmed && (
          <div className="absolute right-[10px] bottom-3 flex flex-col gap-1">
            {subscribed ? (
              <p className="font-SFProTextRegular text-[14px] text-[#499047] text-center cursor-pointer">Active</p>
            ) : (
              <p
                onClick={() => setSubscriptionModalShown(true)}
                className="font-SFProTextRegular text-[14px] text-[#AF0202] text-center cursor-pointer"
              >
                Inactive
              </p>
            )}
            <div className="h-[20px]">
              {/* {subscribed ? <PublishButton clicked={handlePublish} visible={!published} /> : <SaveButton clicked={saveLink} />} */}
              {subscribed ? <ActiveSubscriptionActions handleSave={saveLink} handlePublish={handlePublish} /> : <InactiveSubscriptionActions handleSave={saveLink} handleSubscribe={() => setSubscriptionModalShown(true)}/>}
            </div>
          </div>
        )}
      </div>
      {/* Header End */}
      {/* Content Start */}
      {!linkNameConfirmed ? (
        <div className="w-full h-[60px] flex flex-col items-center pt-[5px] border-t-[0.1px] border-gray-200 relative mt-4">
          <div className="flex items-center">
            <p className="font-SFProTextMedium text-[15px] text-black">{domain}</p>
            <div className="w-[150px] h-[36px] bg-[#F2F6F9] rounded-md px-2 flex items-center ">
              <input
                type="text"
                value={linkName}
                onChange={handleLinkNameChange}
                className="text-[15px] text-inputFontColor w-full bg-transparent font-SFProTextMedium border-b border-gray-500"
              />
            </div>
            {linkNameValid && (
              <img
                onClick={confirmLinkName}
                src={getImageUrl("/images/circle_arrow.png")}
                className="w-[30px] h-[30px] cursor-pointer"
              />
            )}
          </div>
          {!linkNameValid && linkName.length > 0 && (
            <p className="ml-[120px] font-SFProTextRegular text-[12px] text-[#AF0202] text-center cursor-pointer">
              Oops! URL already exists, try again.
            </p>
          )}
        </div>
      ) : (
        <div className="w-full h-[80px] border-t-[0.1px] border-gray-200 mt-[16px] relative ">
          <div className="w-full h-[35px] flex items-center">
            <p className="font-SFProTextMedium text-[14px] w-full text-center text-black">{domain + linkName}</p>
            <img 
              src={getImageUrl("/images/copy_link.png")} 
              className="h-[25px] absolute right-[10px]" 
              onClick={handleCopy(domain + linkName)}
              alt="Copy Link"
            />
          </div>
          <div className="w-full h-[45px] flex items-center justify-center">
            <div className="flex items-center justify-center gap-[20px]">
              <div
                onClick={() => setTabIndex(0)}
                className={`flex items-center w-[130px] h-[30px] border-[1px] rounded-md border-darkBlueColor ${tabBgColor[0]}`}
              >
                <p className={`w-full text-center font-SFProTextRegular text-[12px] ${tabFontColor[0]}`}>Links</p>
              </div>
              <div
                onClick={() => setTabIndex(1)}
                className={`flex items-center  w-[130px] h-[30px] border-[1px] rounded-md border-darkBlueColor ${tabBgColor[1]}`}
              >
                <p className={`w-full text-center font-SFProTextRegular text-[12px] ${tabFontColor[1]}`}>Preview</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-full border-t-[0.1px] border-gray-200 relative overflow-auto pb-[10px]">
        {!linkNameConfirmed && <div className="absolute left-0 right-0 bottom-0 top-0 bg-black opacity-40 z-30" />}
        <div
          onClick={paletteClicked}
          className={`w-[45px] h-[45px] absolute top-[30px] right-[20px] flex items-center justify-center cursor-pointer rounded-[8px] ${disablePalette}`}
          style={{ backgroundColor: `${bgColor}` }}
        >
          {!bgColor && (
            <img src={getImageUrl("/images/palette.png")} className="w-full h-full absolute left-0 right-0 top-0 bottom-0" />
          )}
          <div className="w-[20px] h-[20px] rounded-[10px] bg-white z-10" />
        </div>

        {paletteShown && (
          <div className="absolute z-30 top-[80px] right-[20px]">
            <OutsideClickHandler
              onOutsideClick={() => {
                setPaletteShown(false);
                setBgColor(color);
              }}
            >
              <SketchPicker
                color={color}
                onChange={(color) => {
                  setColor(color.hex);
                }}
              />
            </OutsideClickHandler>
          </div>
        )}

        <div className="flex flex-col items-center mt-3 ">
          <div className={`w-[160px] h-[36px] rounded-md px-2 flex items-center ${titleWrapperClass}`}>
            <input
              type="text"
              value={linkTitle}
              onChange={(e) => {
                setLinkTitle(e.target.value);
              }}
              onFocus={handleTitleFocus}
              onBlur={handleTitleBlur}
              className={`text-[15px] text-inputFontColor w-full bg-transparent font-SFProTextMedium text-center ${titleInputClass}`}
            />
          </div>
          <div className="w-[72px] h-[72px] rounded-[36px] bg-[#C4C4C4] relative mt-4">
            {avatarUrl && <img src={avatarUrl} className="w-[72px] h-[72px] rounded-[36px] object-cover" />}
            <img
              onClick={() => setAvatarModalShown(true)}
              src={getImageUrl("/images/add_photo_button.png")}
              className="w-[24px] h-[24px] absolute right-0 top-0 cursor-pointer"
            />
          </div>

          {/* Social Links Start*/}
          <div className="h-[68px] pt-[10px] flex items-center gap-2 overflow-x-auto overflow-y-hidden mt-[20px]">
            {socialLinks.map((item, index) => (
              // <div className="w-[58px] h-[58px] relative cursor-pointer" key={index}>
              <div
                key={index}
                className="w-[58px] h-[58px] rounded-[29px] bg-[#7EA1C4] flex items-center justify-center relative"
                style={{ backgroundColor: item.color }}
              >
                <SocialLinkLogo socialName={item.icon} />
                <div className="absolute right-[-7px] top-[-7px]">
                  <SocialIconEditButton clicked={() => socialIconEditClicked(index)} />
                </div>
              </div>
              // </div>
            ))}
            {addSocialLinkButtonShown && (
              <div className="w-[58px] h-[58px] flex items-center my-[10px]">
                <AddSocialLinkButton clicked={() => setSocialLinkModalShown(true)} />
              </div>
            )}
          </div>
          {/* Social Links End*/}
          <div className="mt-[20px]">
            <CreateButton clicked={() => setCreateModalShown(true)} />
          </div>

          {/* Link Block Start */}
          <div className="w-full pl-[10px] pr-[10px] mt-5 flex flex-col gap-2">
            {linkblockArray.length === 0 ? (
              <LinkBlockPlaceholder />
            ) : (
              // TODO: currently sorts by created date but need to update
              // to use user specificed order
              linkblockArray.sort((a,b) => {
                return new Date(b.created) - new Date(a.created);
              }).map((item, index) => (
                <div key={index}>
                  <LinkBlock
                    type={item.type}
                    title={item.title}
                    url={item.url}
                    // pBgColor={item.bgColor}
                    // pFontColor={item.fontColor}
                    // pBorderColor={item.borderColor}
                    mainMediaImageUrl={item.mainMediaImageUrl}
                    editClicked={editLink}
                    index={index}
                  />
                </div>
              ))
            )}
          </div>
          {/* Link Block End */}
        </div>
      </div>

      {/* Content End */}
      {/* Footer Start */}
      {/* <div className="absolute left-0 right-0 bottom-0"> */}
      <div className="w-full">
        <Footer />
      </div>
      {/* Footer End */}
    </div>
  );
};

export default Main;
