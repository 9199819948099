import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { getImageUrl } from "src/common/function";
import TitleLinkInputs from "./titlelinkinput";
import UploadMedia from "../../uploadmedia";

const InternalImageAdditionalFields = ({
  subtitle,
  subtitleChanged,
  internalLinks,
  addInternalLinks,
  titleChanged,
  linkChanged,
  linkClicked,
  mediaSelected,
  internalMediaImageUrl,
  linkName
}) => {
  return (
    <div className="w-full">
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] italic">Internal Link Additional Fields</p>
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">Subtitle</p>
      <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
        <input
          value={subtitle}
          onChange={(e) => subtitleChanged(e.target.value)}
          className="w-full font-SFProTextRegular text-[18px] font-[500]"
        />
      </div>

      {/* <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative"> */}
      <UploadMedia label="Add Internal Media" mediaSelected={mediaSelected} defaultImage={internalMediaImageUrl} enabled={true} linkName={linkName} />
      {/* </div> */}

      <div className="flex items-center gap-2 mt-2">
        <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] ">Internal Links</p>
        <div
          onClick={addInternalLinks}
          className="bg-[#7EA1C4] w-[16px] h-[16px] rounded-[8px] flex items-center justify-center cursor-pointer"
        >
          <img src={getImageUrl("/images/plus.png")} className="w-[80%] h-[80%] rounded-[3px]" />
        </div>
      </div>

      {internalLinks.length > 0 ? (
        <div className="bg-[#D9D9D9] rounded-[4px]">
          {internalLinks.map((item, index) => (
            <div key={index}>
              <div onClick={() => linkClicked(index)} className="w-full h-[38px] flex items-center relative gap-1">
                {item.isExpanded ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
                <p className="font-SFProTextRegular text-[14px] text-[#4F4F4F] font-[700] ">{item.title}</p>
                {index < internalLinks.length - 1 && !item.isExpanded && (
                  <div className="h-[1px] bg-[#4F4F4F] absolute bottom-0 left-0 right-0" />
                )}
              </div>
              {item.isExpanded && (
                <TitleLinkInputs
                  title={item.title}
                  titleChanged={(text) => titleChanged(text, index)}
                  link={item.link}
                  linkChanged={(text) => linkChanged(text, index)}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="font-SFProTextRegular text-[14px] text-black font-[400] mt-2 ml-2">No Internal Links added</p>
      )}
    </div>
  );
};

export default InternalImageAdditionalFields;
