import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/common/function";
import { promoCodeArray } from "src/common/constants";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import PublishButton from "./publishbutton";
import CloseButton from "./closebutton";

const SubscriptionModal = ({ shown, cancelClicked, subscribeSuccess }) => {
  const [promoCode, setPromoCode] = useState("");
  const [promoValid, setPromoValid] = useState(true);
  const [inputBorderColor, setInputBorderColor] = useState("#FFFFFF");
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStatus(0);
    setPromoCode("");
    setPromoValid(true);
    setInputBorderColor("#FFFFFF");
    setStatus(0);
    setIsLoading(false);
  }, [shown]);

  const handlePromoCodeChange = (e) => {
    let text = e.target.value;
    setPromoCode(text);
    if (promoCodeArray.some((item) => item === text) || text.length === 0) {
      setPromoValid(true);
      setInputBorderColor("#FFFFFF");
    } else {
      setPromoValid(false);
      setInputBorderColor("#AF0202");
    }
  };

  const handlePromoSuccess = () => {
    if (promoCode.length > 0 && promoValid) {
      setStatus(1);
      subscribeSuccess(promoCode);
    }
  };

  const handleSubscribe = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setStatus(2);
      subscribeSuccess();
    }, 5000);
  };

  const handlePublish = () => {};

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="w-[337px] h-[234px] bg-[#F2F6F9] flex flex-col justify-between relative">
            <div className="absolute right-1 top-1">
              <CloseButton clicked={cancelClicked} />
            </div>
            {isLoading && (
              <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
                <RotatingLines strokeColor="grey" strokeWidth="3" animationDuration="0.75" width="70" visible={true} />
              </div>
            )}
            {status === 0 ? (
              <>
                <div className="w-full h-[50%] flex items-center justify-center gap-5">
                  <p className="font-SFProTextHeavy text-[20px] text-black font-[800]">Start Subscription</p>
                  <img
                    onClick={handleSubscribe}
                    src={getImageUrl("/images/exit.png")}
                    className="w-[41px] h-[41px] cursor-pointer"
                  />
                </div>
                <div className="flex items-center w-full justify-between">
                  <div className="h-[1px] bg-gray-400 w-[40%]" />
                  <p className="font-SFProTextRegular text-[18px] text-[#4F4F4F] italic">Or</p>
                  <div className="h-[1px] bg-gray-400 w-[40%]" />
                </div>
                <div className="w-full h-[50%] flex items-center justify-center">
                  <div className="flex flex-col w-[250px] gap-[5px]">
                    <p className="font-SFProTextRegular text-[18px] text-black font-[700]">Enter Promo</p>
                    <div className="w-full h-[36px] flex items-center gap-2">
                      <div
                        className="bg-[#FFFEFC] rounded-[10px] w-full h-full px-1 flex items-center border-[1px] py-1"
                        style={{ borderColor: inputBorderColor }}
                      >
                        <input
                          value={promoCode}
                          onChange={handlePromoCodeChange}
                          className="w-full h-full font-SFProTextRegular text-[18px] text-black font-[700]"
                        />
                      </div>
                      <img
                        onClick={handlePromoSuccess}
                        src={getImageUrl("/images/subscription_arrow.png")}
                        className="w-[17px] cursor-pointer"
                        alt="subscription arrow"
                      />
                    </div>
                    <p
                      className="font-SFProTextRegular text-[12px] text-[#AF0202] font-[700] ml-3"
                      style={{ visibility: !promoValid ? "visible" : "hidden" }}
                    >
                      Invalid Promo code
                    </p>
                  </div>
                </div>
              </>
            ) : status === 1 ? (
              <div className="w-full h-full px-[30px] pt-[40px] flex flex-col items-center">
                <p className="font-SFProTextHeavy text-[20px] text-black font-[800] w-full">Thanks for supporting!</p>
                <p className="font-SFProTextRegular text-[17px] text-black font-[400] mt-[20px]">
                  Your Promo code will be reviewed and an email will be sent out shortly!
                </p>
                <img src={getImageUrl("/images/second_logo.png")} className="w-[50px]" />
              </div>
            ) : (
              <div className="w-full h-full px-[30px] pt-[40px] flex flex-col items-center">
                <p className="font-SFProTextHeavy text-[20px] text-black font-[800] w-full">Thanks for supporting!</p>
                <p className="font-SFProTextRegular text-[17px] text-black font-[400] mt-[20px] mb-[10px]">
                  When your ready to go live remember to “Publish{" "}
                  <img src={getImageUrl("/images/publish_black.png")} className="w-[12px] h-[12px] inline " />“ your Right-Link!
                </p>
                <PublishButton clicked={handlePublish} />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default SubscriptionModal;
