const BioContentInput = ({ header, headerChanged, body, bodyChanged }) => {
  return (
    <div className="w-full border-l-[1px] border-b-[1px] border-r-[1px] bg-[#F2F6F9] px-[20px] py-[5px]">
      <div className="w-full">
        <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] ">Header</p>
        <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
          <input
            value={header}
            onChange={(e) => headerChanged(e.target.value)}
            className="w-full font-SFProTextRegular text-[14px] font-[500]"
          />
        </div>
      </div>
      <div className="w-full">
        <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[5px] ">Body</p>
        <div className="w-full h-[80px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
          {/* <input
            value={link}
            onChange={(e) => linkChanged(e.target.value)}
            className="w-full font-SFProTextRegular text-[14px] font-[500]"
          /> */}

          <textarea
            value={body}
            onChange={(e) => bodyChanged(e.target.value)}
            rows={3}
            className="w-full font-SFProTextRegular text-[14px] font-[500] resize-none focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};

export default BioContentInput;
