import { getImageUrl } from "src/common/function";

const ActiveSubscriptionActions = ({handleSave, handlePublish}) => {
    // break out into individual button components
    return (
        <div className="flex gap-1">
            <div onClick={handleSave} className="w-[75px] h-[31px] bg-darkBlueColor flex items-center justify-center rounded-[4px]">
                <p className="font-SFProTextRegular text-[12px] text-white font-[700]">Save</p>
            </div>
            <div
                onClick={handlePublish}
                className="w-[75px] h-[31px] bg-[#1B365C] rounded-[4px] flex items-center justify-center cursor-pointer gap-2"
                // style={{ visibility: visible ? "visible" : "hidden" }}
                >
                <p className="font-SFProTextRegular text-[12px] text-white font-[700]">Publish</p>
                <img src={getImageUrl("/images/publish_white.png")} className="w-[12px] h-[12px]" />
            </div>
        </div>
    )
}

export default ActiveSubscriptionActions;