import { useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { validImageTypes } from "src/common/constants";
import { getImageUrl } from "src/common/function";
import uploadToCloudinary from "src/common/uploadToCloudinary";

const AvatarModal = ({ shown, cancelClicked, avatarSelected, linkName }) => {
  const fileInputRef = useRef(null);

  const uploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    console.log('handleImageChange e', e)
    e.preventDefault()

    const uploadedImgUrl = await uploadToCloudinary(e.target.files[0], linkName)
    avatarSelected(uploadedImgUrl)
  }

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="bg-[#FFFEFC] w-[232px] h-[148] px-2 flex flex-col items-center pt-3 pb-5 rounded-sm">
            <p className="font-SFProTextRegular text-[18px] text-black">Profile Image</p>
            <div className="w-full h-[1px] bg-black mt-2" />
            <div className="mt-3 flex flex-col items-center cursor-pointer" onClick={uploadClick}>
              <img src={getImageUrl("/images/avatar.png")} className="h-[30px]" />
              <p className="font-SFProTextRegular text-[12px] text-[#444A4A] mt-2">Profile Image</p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              className="hidden"
              accept={validImageTypes.toString()}
            />
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default AvatarModal;
