import { useEffect, useState } from "react";
import { getImageUrl } from "src/common/function";
import LinkItem from "src/pages/home/linkitem";
import { useNavigate } from "react-router-dom";
import TokenService from "src/services/token.service";
import LinkService from "src/services/link.service";
import { handleError } from "src/common/function";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { useDispatch } from "react-redux";
import { setAuthState } from "src/redux/slices/authslice";
import AuthService from "src/services/auth.service";

const SideMenu = ({ shown, onClose }) => {
  const currentUser = TokenService.getUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [links, setLinks] = useState([]);
  const [width, setWidth] = useState("0px");
  const screenWidth = window.innerWidth;

  useEffect(() => {
    LinkService.getLinks({ userId: currentUser.id })
      .then((response) => {
        if (response.status === 200) {
          setLinks(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (shown) {
      if (screenWidth < 330) {
        setWidth(screenWidth);
      } else {
        setWidth("330px");
      }
    }
  }, [shown]);

  const linkClicked = (index) => {
    navigate(`/manage/${links[index].name}`);
    handleClose();
  };

  const handleClose = () => {
    setWidth("0px");
    onClose();
  };

  const signOut = () => {
    dispatch(setAuthState(false));
    AuthService.logout();
  };

  return (
    shown && (
      <div className="w-screen h-screen fixed left-0 right-0 top-0 bottom-0 z-50 flex bg-[#0000007F]">
        <OutsideClickHandler onOutsideClick={handleClose}>
          <div
            className="h-full bg-[#F2F6F9] flex flex-col"
            style={{
              width: width,
              transition: "width 0.1s ease",
            }}
          >
            <div className="w-full h-[80px] flex items-center justify-center">
              <img
                onClick={handleClose}
                src={getImageUrl("/images/big_close.png")}
                className="h-[40px] absolute left-[10px] cursor-pointer"
              />
              <p className="font-SFProTextBold text-[20px] text-[#1B365C]">Account Menu</p>
            </div>
            <div className="w-full h-[1px] bg-[#C4C4C4]" />
            <div className="w-full h-[80px] flex items-center justify-center">
              <p className="font-SFProTextBold text-[20px] text-[#1B365C]">Manage Another Right-Link</p>
            </div>
            <div className="w-full h-[1px] bg-[#C4C4C4]" />
            <div className="w-full flex-1 overflow-y-auto">
              {links.map((link, index) => (
                <div key={index}>
                  <LinkItem links={links} link={link} index={index} linkClicked={linkClicked} />
                </div>
              ))}
            </div>
            <div
              onClick={() => {
                handleClose();
                navigate("/manage");
              }}
              className="w-full h-[60px] bg-white flex items-center justify-center cursor-pointer"
            >
              <p className="font-SFProTextRegular text-[18px] text-[#1B365C]">Create New Right-Link</p>
            </div>
            <div className="w-full h-[1px] bg-[#C4C4C4]" />
            <div onClick={signOut} className="w-full h-[60px] bg-white flex items-center justify-center cursor-pointer">
              <p className="font-SFProTextRegular text-[18px] text-[#1B365C]">Sign Out</p>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default SideMenu;
