const ButtonGroup = ({ save, cancel }) => {
  return (
    <div className="w-full h-[40px] flex gap-5">
      <div
        onClick={save}
        className="flex items-center justify-center w-[50%] rounded-md bg-darkBlueColor font-SFProTextRegular text-[14px] text-white"
      >
        Save
      </div>
      <div
        onClick={cancel}
        className="flex items-center justify-center w-[50%] rounded-md bg-transparent font-SFProTextRegular text-[14px] text-darkBlueColor"
      >
        Cancel
      </div>
    </div>
  );
};

export default ButtonGroup;
