import { getImageUrl } from "src/common/function";

const AddSocialLinkButton = ({ clicked }) => {
  return (
    <div
      onClick={clicked}
      className="w-[58px] h-[58px] rounded-[29px] bg-[#7EA1C4] flex items-center justify-center cursor-pointer"
    >
      <img src={getImageUrl("/images/plus.png")} className="w-[24px] h-[24px]" />
    </div>
  );
};

export default AddSocialLinkButton;
