const CreateButton = ({ clicked }) => {
  return (
    <div
      onClick={clicked}
      className="bg-[#7EA1C4] rounded-[12px] flex items-center justify-center w-[270px] h-[49px]"
    >
      <div className="flex items-center gap-1">
        <p className="font-SFProTextRegular text-[22px] text-white">+</p>
        <p className="font-SFProTextRegular text-[18px] text-white">Create</p>
      </div>
    </div>
  );
};

export default CreateButton;
