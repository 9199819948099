import { getImageUrl } from "src/common/function";

const SocialIconEditButton = ({ clicked }) => {
  return (
    <div
      onClick={clicked}
      className="w-[24px] h-[24px] rounded-[12px] bg-[#7EA1C4] cursor-pointer flex items-center justify-center"
    >
      <img src={getImageUrl("/images/edit_white.png")} className="w-[16px] " />
    </div>
  );
};

export default SocialIconEditButton;
