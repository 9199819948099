import api from "./api";

const saveLink = (data) => {
  return api.post("/api/link/save", data);
};

const getLinks = (data) => {
  return api.get("/api/link/get_all_links", { params: data });
};

const checkLinkValidation = (data) => {
  return api.get("/api/link/checknamevalidation", { params: data });
};

const publishLink = (data) => {
  return api.post("/api/link/publish", data);
};

const LinkService = {
  saveLink,
  getLinks,
  checkLinkValidation,
  publishLink,
};

export default LinkService;
