const InactiveSubscriptionActions = ({handleSave, handleSubscribe}) => {
    return (
        <div className="flex gap-1">
            <div onClick={handleSubscribe} className="w-[75px] h-[31px] bg-[#1B365C] flex items-center justify-center rounded-[4px]">
                <p className="font-SFProTextLight text-[12px] text-white font-[700]">Subscribe</p>
            </div>
            <div onClick={handleSave} className="w-[75px] h-[31px] bg-darkBlueColor flex items-center justify-center rounded-[4px]">
                <p className="font-SFProTextLight text-[12px] text-white font-[700]">Save</p>
            </div>
        </div>
    )
}

export default InactiveSubscriptionActions;