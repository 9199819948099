import { useEffect, useState } from "react";
import { getImageUrl } from "src/common/function";

const PageSwitch = ({ title, clicked }) => {
  return (
    <div className="flex justify-center w-[290px] mt-2">
      <span onClick={clicked} className="font-SFProTextRegular text-[12px] text-darkBlueColor cursor-pointer">
        {title}
      </span>
    </div>
  );
};

export default PageSwitch;
