import { useEffect, useState, useRef } from "react";
import { validImageTypes } from "src/common/constants";
import { getImageUrl } from "src/common/function";
import isUrlValid from "src/common/isUrlValid";
import uploadToCloudinary from "src/common/uploadToCloudinary";
import InvalidLinkMessage from "src/components/invalidlinkmessage";

const SlideShowAdditionalFields = ({
  slideshowSpeed,
  slideshowSpeedChanged,
  slideshowImages,
  addSlideshowImage,
  fileSelected,
  linkName,
}) => {
  const fileInputRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [inputsValidation, setInputsValidation] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState(null);

  useEffect(() => {
    let temp = [];
    for (let image of slideshowImages) {
      if (image.type === 0 || image.type === 1) {
        temp.push(image.imageUrl);
      } else {
        temp.push("");
      }
    }
    setInputs(temp);

    const checkValidation = async () => {
      let tempValidations = [];
      for (let image of slideshowImages) {
        if (image.type === 0) {
          if (isUrlValid(image.imageUrl)) {
            tempValidations.push(true);
          } else {
            tempValidations.push(false);
          }
        } else {
          tempValidations.push(true);
        }
      }
      setInputsValidation(tempValidations);
    };
    checkValidation();
  }, [slideshowImages]);

  const handleImageChange = async (e) => {
    e.preventDefault()

    const selectedFile = e.target.files[0];

    const uploadedImgUrl = await uploadToCloudinary(selectedFile, linkName)
    fileSelected(1, selectedFile.name, uploadedImgUrl, imageIndex);

    //set filename to input
    setSelectedFileName(selectedFile.name);
  }

  useEffect(() => {
    if (selectedFileName) {
      let temp = [...inputs];
      temp[imageIndex] = selectedFileName;
      setInputs(temp);
      setSelectedFileName(null);
    }
  }, [selectedFileName]);

  const handleInputChange = async (e, index) => {
    if (selectedFileName) return;
    const inputFieldVal = e.target.value;
    let temp = [...inputs];
    temp[index] = inputFieldVal;
    setInputs(temp);
    fileSelected(0, null, null, index, inputFieldVal);

    //validation check
    let tempValidations = [...inputsValidation];
    if (isUrlValid(inputFieldVal)) {
      tempValidations[index] = true;
    } else {
      tempValidations[index] = false;
    }

    setInputsValidation(tempValidations);
  };

  return (
    <div className="w-full">
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] italic">Slideshow Link Fields</p>
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">Slideshow speed</p>
      <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
        <input
          value={slideshowSpeed}
          onChange={(e) => slideshowSpeedChanged(e.target.value)}
          className="w-full font-SFProTextRegular text-[16px] font-[500]"
        />
        <p className="font-SFProTextRegular italic text-[15px] text-[#4F4F4F] font-[400] ">seconds</p>
      </div>
      <div className="flex items-center gap-2 mt-2">
        <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] ">Image Collection</p>
        <div
          onClick={addSlideshowImage}
          className="bg-[#7EA1C4] w-[16px] h-[16px] rounded-[8px] flex items-center justify-center cursor-pointer"
        >
          <img src={getImageUrl("/images/plus.png")} className="w-[80%] h-[80%] rounded-[3px]" />
        </div>
      </div>

      {slideshowImages.length > 0 ? (
        slideshowImages.map((image, index) => (
          <div className="flex flex-col" key={index}>
            <div className="w-full h-[37px] flex items-center gap-1">
              <p className="font-SFProTextRegular text-[14px] text-[#4F4F4F] font-[400]">{index + 1}.</p>
              <div className="flex-1 bg-white rounded-md h-[30px] flex items-center px-1">
                <input
                  value={inputs[index]}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full font-SFProTextRegular text-[14px] font-[400]"
                />
              </div>

              <img
                onClick={() => {
                  setImageIndex(index);
                  fileInputRef.current.click();
                }}
                src={getImageUrl("/images/upload.png")}
                className="w-[30px] h-[25px] cursor-pointer"
              />
            </div>
            <InvalidLinkMessage shown={!inputsValidation[index]} />
          </div>
        ))
      ) : (
        <p className="font-SFProTextRegular text-[14px] text-black font-[400] mt-2 ml-2">No images added</p>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={(event) => handleImageChange(event)}
        className="hidden"
        accept={validImageTypes.toString()}
      />
    </div>
  );
};

export default SlideShowAdditionalFields;
