const InvalidLinkMessage = ({ shown }) => {
  return (
    shown && (
      <div>
        <p className="w-full font-SFProTextRegular text-[12px] font-[700] ml-2 text-red-400">
          Invalid URL/Link. Please enter a valid URL.
        </p>
      </div>
    )
  );
};

export default InvalidLinkMessage;
