import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";

import { getImageUrl, handleError } from "src/common/function";
import NormalInput from "../components/normalinput";
import AuthService from "src/services/auth.service";
import SignButton from "src/pages/auth/components/signbutton";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (email.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email]);

  const sendEmail = () => {
    AuthService.sendForgotPasswordEmail(email)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast("Reset email has been sent");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className="w-screen h-screen pt-[50px]">
      <div className="w-full h-[80px] pb-[20px] border-b-[1px] border-gray-400">
        <div className="w-full h-[60px] flex items-center justify-center relative">
          <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" alt="" />
          <div onClick={() => navigate("/landing")} className="absolute left-[20px] bottom-[-10px]">
            <MdKeyboardBackspace size={35} color="#3F3F3F" />
          </div>
        </div>
      </div>

      <p className="font-SFProTextSemiBold text-[26px] text-darkGray text-center tracking-tighter mt-4">Forgot Password</p>

      <div className="pl-5 mt-[40px] gap-6 relative">
        <NormalInput type="email" title="Email" value={email} valueChanged={(value) => setEmail(value)} />
      </div>

      <div className="pl-5 mt-[100px]">
        <SignButton title="Send Email" validation={isValid} clicked={sendEmail} />
      </div>
    </div>
  );
};

export default ForgotPassword;
