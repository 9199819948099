import { useEffect, useState } from "react";
import LinkBlockPreviewPlaceholder from "../linkblockpreviewplaceholder/linkblockpreviewplaceholder";

const ExternalLinkPreview = ({ pBgColor, pFontColor, pBorderColor, pTitle }) => {
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [borderColor, setBorderColor] = useState(null);

  useEffect(() => {
    console.log("pBgColor: ", pBgColor);
    setBgColor(pBgColor?.name);
  }, [pBgColor]);

  useEffect(() => {
    setFontColor(pFontColor?.name);
  }, [pFontColor]);

  useEffect(() => {
    setBorderColor(pBorderColor?.name);
  }, [pBorderColor]);

  return (
    <>
      {pTitle.length === 0 ? (
        <LinkBlockPreviewPlaceholder />
      ) : (
        <div
          className="w-full h-[70px] border-dashed border-[1px] border-[#4F4F4F] rounded-[12px] flex items-center justify-center relative"
          style={{ backgroundColor: bgColor }}
        >
          <p className="font-SFProTextRegular text-[14px] font-[700] text-center" style={{ color: fontColor }}>
            {pTitle}
          </p>
          {/* border */}
          <div
            className="absolute z-40 left-0 right-0 top-[-1px] rounded-tl-[12px] rounded-tr-[12px] h-[10px]"
            style={{ backgroundColor: borderColor }}
          />
        </div>
      )}
    </>
  );
};

export default ExternalLinkPreview;
