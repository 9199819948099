import { validImageTypes } from "src/common/constants";
import TokenService from "src/services/token.service";
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET } from "src/assets/config";

const uploadToCloudinary = async (selectedImageFile, linkName) => {
    const currentUser = TokenService.getUser();

    try {
      if (selectedImageFile && validImageTypes.includes(selectedImageFile.type)) {
        const image = new FormData();
        image.append("file", selectedImageFile);
        image.append("folder", `right-link-portal/${currentUser.id}/${linkName}`)
        image.append("cloud_name", CLOUDINARY_CLOUD_NAME);
        image.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
          {
            method: 'post',
            body: image
          }
        )

        const imageData = await response.json()
        const imageUrl = imageData.url.toString() 
        console.log('file uploaded to cloudinary:', imageUrl)

        return imageUrl;
      }
      
    } catch(error) {
        // TODO: may need to add a toaster message for this error.
        console.log('Error: File uploading to cloudinary:', error)
    }
}

export default uploadToCloudinary;
