import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/common/function";
import CloseButton from "./closebutton";

const PublishModal = ({ shown, cancelClicked }) => {
  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="w-[337px] h-[234px] bg-[#F2F6F9] px-[20px] py-[30px] relative">
            <div className="absolute right-1 top-1">
              <CloseButton clicked={cancelClicked} />
            </div>
            <div className="w-full flex items-center justify-center">
              <p className="font-SFProTextHeavy text-[20px] text-[#4F4F4F] font-[800]">Thanks for publishing</p>
              <img src={getImageUrl("/images/flight.png")} className="w-[41px] h-[41px]" />
            </div>
            <p className="font-SFProTextRegular text-[17px] text-black font-[400] mt-[30px] text-center">
              Your Right-Link, it will be available in less than 5mins!
            </p>
            {/* <p className="font-SFProTextRegular text-[17px] text-black font-[400] mt-[20px] text-center">
              Your Right-Link will be LIVE in less than 5mins!
            </p> */}
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default PublishModal;
