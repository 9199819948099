import { useEffect, useState } from "react";
import { getImageUrl } from "src/common/function";
import { socialNetworkArray } from "src/common/constants";

const SocialLogoDropdown = ({ itemSelected, selectedLinkIndex, socialLinkArray }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [keyword, setKeyword] = useState("");

  const itemClicked = (index) => {
    setDropdownOpen(false);
    setKeyword(resultArray[index]);
    itemSelected(resultArray[index]);
  };

  const handleKeywordChange = (e) => {
    const inputString = e.target.value;
    setKeyword(inputString);
    const result = socialNetworkArray.filter((item) => item.toLowerCase().includes(inputString.toLowerCase()));
    setResultArray(result);
  };

  useEffect(() => {
    if (resultArray.length > 0) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [resultArray]);

  useEffect(() => {
    setKeyword(socialLinkArray[selectedLinkIndex]?.icon ? socialLinkArray[selectedLinkIndex]?.icon : "");
  }, [selectedLinkIndex]);

  return (
    <div className="w-full h-[36px] bg-white flex items-center px-2 rounded-md relative gap-1">
      <img src={getImageUrl("/images/search_icon.png")} alt="" className="w-[30px] h-[25px] object-contain" />
      <input
        value={keyword}
        onChange={handleKeywordChange}
        className="text-[#444A4A] font-SFProTextRegular text-[16px] font-[500] w-full"
      />

      {/* <FiChevronDown size={15} /> */}
      {dropdownOpen && (
        <div className="w-full absolute max-h-[200px] top-[36px] left-0 bg-white overflow-auto rounded-sm">
          {resultArray.map((item, index) => (
            <div onClick={() => itemClicked(index)} className="w-full h-[30px] px-2" key={index}>
              <p className="font-SFProTextRegular text-[16px] text-[#444A4A] font-[500] ">{item}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SocialLogoDropdown;
