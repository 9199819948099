import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import CloseButton from "../../../components/closebutton";

import { getImageUrl } from "src/common/function";

const CreateModal = ({ shown, cancelClicked, socialLinkModalClicked, linkBlockModalClicked }) => {
  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="bg-[#F2F6F9] w-[284px] h-[512] flex flex-col items-center rounded-sm">
            <div className="w-full h-[44px] flex items-center justify-between bg-white rounded-t-md px-[20px]">
              <p className="font-SFProTextHeavy text-[20px] text-[#4F4F4F] font-[800]">Create</p>
              <CloseButton clicked={cancelClicked} />
            </div>

            <div className="w-full px-3 py-4">
              <p className="font-SFProTextRegular w-full text-[16px] text-[#4F4F4F] font-[700]">Add a link</p>
              <p className="font-SFProTextRegular w-full text-[12px] text-[#4F4F4F] font-[400]">
                Choose how your link will be viewed by your audience!
              </p>

              <div className="w-[252px] h-[149px] rounded-[12px] mt-[15px] flex items-center justify-center bg-white">
                <div className="flex flex-col items-center gap-4">
                  <div
                    onClick={socialLinkModalClicked}
                    className="cursor-pointer border-[#444A4A] border-[2px] w-[177px] h-[31px] rounded-[4px] flex items-center justify-center"
                  >
                    <p className="font-SFProTextRegular w-full text-[12px] text-[#4F4F4F] font-[500] text-center">Social Links</p>
                  </div>
                  <div className="border-[#444A4A] border-[2px] border-dashed w-[154px] h-[45px] rounded-[12px] flex items-center justify-center">
                    <div className="flex items-center gap-3">
                      <div className="w-[28px] h-[28px] rounded-[14px] bg-[#444A4A]" />
                      <div className="w-[28px] h-[28px] rounded-[14px] bg-[#444A4A]" />
                      <div className="w-[28px] h-[28px] rounded-[14px] bg-[#444A4A] flex items-center justify-center">
                        <img src={getImageUrl("/images/plus.png")} className="w-[15px] h-[15px]" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="font-SFProTextRegular w-full text-[12px] text-[#4F4F4F] mt-[10px] mb-[10px] font-[700] text-center">
                or
              </p>

              <div className="w-[252px] h-[149px] rounded-[12px] mb-[25px] flex items-center justify-center bg-white">
                <div className="flex flex-col items-center gap-4">
                  <div
                    onClick={linkBlockModalClicked}
                    className="cursor-pointer border-[#444A4A] border-[2px] w-[177px] h-[31px] rounded-[4px] flex items-center justify-center"
                  >
                    <p className="font-SFProTextRegular w-full text-[12px] text-[#4F4F4F] font-[500] text-center">Link blocks</p>
                  </div>
                  <div className="border-[#444A4A] border-[2px] border-dashed w-[154px] h-[45px] rounded-[12px] flex items-center pl-[10px]">
                    <div className="flex items-center gap-1">
                      <img src={getImageUrl("/images/black_gallery.png")} className="w-[34px] h-[34px]" />
                      <div className="w-fll h-[28px] flex flex-col justify-between">
                        <span className="font-SFProTextRegular w-full text-[7px] text-[#4F4F4F] font-[500]">External</span>
                        <span className="font-SFProTextHeavy w-full text-[7px] text-[#4F4F4F] font-[700]">Title</span>
                        <span className="font-SFProTextRegular w-full text-[7px] text-[#4F4F4F] font-[500]">Enter Link URL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default CreateModal;
