import { toast } from "react-toastify";

export const isAuthenticatedWithToken = () => {
  const token = localStorage.getItem("accessToken"); // or sessionStorage.getItem('jwtToken');
  if (token) {
    return true;
  }
  return false;
};

export const getImageUrl = (imagePath) => {
  return process.env.PUBLIC_URL + imagePath;
};

export const handleError = (error) => {
  console.log("error: ", error);
  if (error.response) {
    toast(error.response.data.message);
  } else {
    toast("An error occured");
  }
};

export const urlToBase64 = (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataUrl(blob);
        })
    );
};

export const isValidImageUrl = (url, callback) => {
  return new Promise(function (resolve) {
    var img = new Image();
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = url;
  });
};
