import { useEffect, useState } from "react";
import { getImageUrl } from "src/common/function";

const Footer = () => {
  return (
    <div className="w-full h-[89px] bg-[#7EA1C41A] flex items-center justify-center">
      <div className="flex items-center gap-3">
        <span className="font-SFProTextRegular text-[12px]">Powered By The Right Link</span>
        <img src={getImageUrl("/images/second_logo.png")} className="w-[50px] h-[50px]" />
      </div>
    </div>
  );
};

export default Footer;
