import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import LinkBlockPreviewPlaceholder from "../linkblockpreviewplaceholder/linkblockpreviewplaceholder";

const MediaLinkPreview = ({ pBgColor, pFontColor, pBorderColor, pTitle, url }) => {
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [borderColor, setBorderColor] = useState(null);

  useEffect(() => {
    setBgColor(pBgColor?.name);
  }, [pBgColor]);

  useEffect(() => {
    setFontColor(pFontColor?.name);
  }, [pFontColor]);

  useEffect(() => {
    setBorderColor(pBorderColor?.name);
  }, [pBorderColor]);

  return (
    <>
      {pTitle.length === 0 && url.length === 0 ? (
        <LinkBlockPreviewPlaceholder />
      ) : (
        <div
          className="w-full border-dashed border-[1px] border-[#4F4F4F] rounded-[12px] flex flex-col items-center py-[20px] gap-[20px] relative"
          style={{ backgroundColor: bgColor }}
        >
          <ReactPlayer url={url} width="90%" height="160px" />
          <p className="font-SFProTextRegular text-[14px] text-black font-[700]" style={{ color: fontColor }}>
            {pTitle}
          </p>
          {/* border */}
          <div
            className="absolute z-40 left-0 right-0 top-[-1px] rounded-tl-[12px] rounded-tr-[12px] h-[10px]"
            style={{ backgroundColor: borderColor }}
          />
        </div>
      )}
    </>
  );
};

export default MediaLinkPreview;
