import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import ScreenOutOfBounds from "./pages/ScreenOutOfBounds";
import AppContainer from "./AppContainer";

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth > 640 ? (
    <ScreenOutOfBounds />
  ) : (
    <AppContainer />
  );
}

export default App;
