import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getImageUrl } from "src/common/function";

const SigninButton = ({ clicked }) => {
  useEffect(() => {});

  return (
    <div
      onClick={clicked}
      className="w-[80px] h-[35px] rounded-md bg-darkBlueColor flex items-center justify-center cursor-pointer"
    >
      <p className="font-SFProTextRegular text-[11px] text-white">Sign In</p>
    </div>
  );
};

export default SigninButton;
