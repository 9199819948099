const ScreenOutOfBounds = () => {
    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <p className="font-SFProTextMedium text-[15px] text-black">
                Right-link Portal is currently only available on mobile devices
            </p>
        </div>
    )
}

export default ScreenOutOfBounds;
