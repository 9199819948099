const TitleInput = ({ text, textChanged }) => {
  return (
    <div className="w-full">
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">
        Title
      </p>
      <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
        <input
          value={text}
          onChange={(e) => textChanged(e.target.value)}
          className="w-full font-SFProTextRegular text-[18px] font-[500]"
        />
      </div>
    </div>
  );
};

export default TitleInput;
