import { getImageUrl } from "src/common/function";
import TokenService from "src/services/token.service";

const LinkItem = ({ links, link, index, linkClicked }) => {
  const currentUser = TokenService.getUser();
  return (
    <div
      onClick={() => linkClicked(index)}
      className="w-full h-[110px] flex items-center px-[20px] gap-[10px] cursor-pointer relative"
    >
      {link.avatarUrl ? (
        <img
          src={link.avatarUrl}
          className="w-[90px] h-[90px] rounded-[45px] object-contain"
          alt=""
        />
      ) : (
        <div className="w-[90px] h-[90px] flex items-center justify-center">
          <img src={getImageUrl("/images/avatar_placeholder.png")} className="w-[70px] h-[70px] rounded-[35px] object-contain" />
        </div>
      )}

      <p className="font-roboto-mono text-[16px] text-[#444A4A]">right-link.com/{link.name}</p>
      {index < links.length - 1 && <div className="w-[90%] left-[5%] h-[1px] absolute bottom-0 bg-gray-200" />}
    </div>
  );
};

export default LinkItem;
