import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";

import { getImageUrl, handleError } from "src/common/function";
import NormalInput from "../components/normalinput";
import AuthService from "src/services/auth.service";
import SignButton from "src/pages/auth/components/signbutton";

const ResetPassword = () => {
  const navigate = useNavigate();

  let { userId, token } = useParams();

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(undefined);

  const [isAllValid, setIsAllValid] = useState(false);

  useEffect(() => {
    if (password.length === 0 || confirmPassword.length === 0) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
    }
  }, [password, confirmPassword]);

  const resetPassword = () => {
    if (!checkPasswordValidation()) return;

    AuthService.resetPassword(userId, token, password)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          toast("Password has been reset successfully");
          navigate("/signin");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const checkPasswordValidation = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>.]{8,}$/;
    const isValid = passwordPattern.test(password);
    if (!isValid) {
      setIsPasswordValid(isValid);
      setPasswordErrorMessage(
        "Password must contain: <br>  &nbsp;&middot; At least 1 upper-case &nbsp;&nbsp;&nbsp;&nbsp&middot; At least 1 number <br> &nbsp;&middot; At least 1 lower-case &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&middot; At least 8 charaters <br> &nbsp;&middot; At least 1 special character"
      );
      return false;
    } else {
      setIsPasswordValid(true);
      if (password !== confirmPassword) {
        setIsConfirmPasswordValid(false);
        setPasswordErrorMessage("Password does not match");
        return false;
      } else {
        setIsConfirmPasswordValid(true);
        setPasswordErrorMessage("");
      }
    }
    return true;
  };

  return (
    <div className="w-screen h-screen pt-[50px]">
      <div className="w-full h-[80px] pb-[20px] border-b-[1px] border-gray-400">
        <div className="w-full h-[60px] flex items-center justify-center relative">
          <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" alt="" />
          <div onClick={() => navigate("/landing")} className="absolute left-[20px] bottom-[-10px]">
            <MdKeyboardBackspace size={35} color="#3F3F3F" />
          </div>
        </div>
      </div>

      <p className="font-SFProTextSemiBold text-[26px] text-darkGray text-center tracking-tighter mt-4">Reset Password</p>

      <div className="pl-5 mt-[30px] flex flex-col gap-1">
        <div className="w-[290px]">
          <p className="font-SFProTextSemiBold text-[16px] text-darkGray">Password</p>
          <div className={`flex items-center w-full h-[39px] rounded-[10px] bg-inputBgColor px-3 mt-2`}>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              className="text-[17px] text-inputFontColor w-full bg-transparent font-SFProTextRegular"
            />
            {isPasswordValid && (
              <img src={getImageUrl("/images/authentication/check.png")} className="w-[25px] h-[25px]" alt="" />
            )}
          </div>
          <div className={`flex items-center w-full h-[39px] rounded-[10px] bg-inputBgColor px-3 mt-2`}>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              placeholder="Confirm Password"
              className="text-[17px] text-inputFontColor w-full bg-transparent font-SFProTextRegular"
            />
            {isConfirmPasswordValid && (
              <img src={getImageUrl("/images/authentication/check.png")} className="w-[25px] h-[25px]" alt="" />
            )}
          </div>

          <div
            className="font-SFProTextRegular text-[14px] text-darkRedColor tracking-tighter"
            dangerouslySetInnerHTML={{ __html: passwordErrorMessage }}
          />
        </div>
      </div>

      <div className="pl-5 mt-[100px]">
        <SignButton title="Reset Password" validation={isAllValid} clicked={resetPassword} />
      </div>
    </div>
  );
};

export default ResetPassword;
