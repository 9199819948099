import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "src/common/function";

const Footer = ({ clicked }) => {
  useEffect(() => {});

  return (
    <div onClick={clicked} className="w-full h-[89px] bg-darkBlueColor flex items-center justify-center">
      <div className="flex items-center gap-4">
        <p className="font-SFProTextLight text-[12px] text-white">Powered by</p>
        <img src={getImageUrl("/images/main_logo.png")} className="h-[40px]" />
      </div>
    </div>
  );
};

export default Footer;
