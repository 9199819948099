import { getImageUrl } from "src/common/function";
const PublishButton = ({ clicked, visible }) => {
  return (
    <div
      onClick={clicked}
      className="w-[85px] h-[31px] bg-[#1B365C] rounded-[4px] flex items-center justify-center cursor-pointer gap-2"
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <p className="font-SFProTextRegular text-[12px] text-white font-[700]">Publish</p>
      <img src={getImageUrl("/images/publish_white.png")} className="w-[12px] h-[12px] " />
    </div>
  );
};

export default PublishButton;
