import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import LinkBlockPreviewPlaceholder from "../linkblockpreviewplaceholder/linkblockpreviewplaceholder";

const SlideShowLinkPreview = ({ pBgColor, pFontColor, pBorderColor, pTitle, images, slideshowSpeed }) => {
  const [imageArray, setImageArray] = useState([]);
  const [bgColor, setBgColor] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [borderColor, setBorderColor] = useState(null);

  useEffect(() => {
    setBgColor(pBgColor?.name);
  }, [pBgColor]);

  useEffect(() => {
    setFontColor(pFontColor?.name);
  }, [pFontColor]);

  useEffect(() => {
    setBorderColor(pBorderColor?.name);
  }, [pBorderColor]);

  useEffect(() => {
    setImageArray(images.filter((item) => item.type > -1));
  }, [images]);

  return (
    <>
      {pTitle.length === 0 && imageArray.length === 0 ? (
        <LinkBlockPreviewPlaceholder />
      ) : (
        <div className="w-full h-[187px] pt-[9px] rounded-[12px] relative" style={{ backgroundColor: bgColor }}>
          <div className="flex items-center justify-between h-[150px]">
            <div className="flex-1 h-[150px] bg-slate-900">
              <Carousel
                swipeable={true}
                showThumbs={false}
                emulateTouch={true}
                interval={slideshowSpeed * 1000}
                autoPlay={true}
                infiniteLoop={true}
              >
                {imageArray.map((item, index) =>
                  item.type === 0 ? (
                    <div className="w-full h-[150px]" key={index}>
                      <img src={item.imageUrl} className="h-[150px] object-contain" alt={`${index}`} />
                    </div>
                  ) : item.type === 1 ? (
                    <div className="w-full h-[150px]" key={index}>
                      <img src={item.imageUrl} className="h-[150px] object-contain" alt={`${index}`} />
                    </div>
                  ) : null
                )}
              </Carousel>
            </div>
          </div>
          <p
            className="font-SFProTextRegular text-[14px] text-[#3F3F3F] font-[700] text-center mt-[5px]"
            style={{ color: fontColor }}
          >
            {pTitle}
          </p>
          {/* border */}
          <div
            className="absolute z-40 left-0 right-0 top-[-1px] rounded-tl-[12px] rounded-tr-[12px] h-[10px]"
            style={{ backgroundColor: borderColor }}
          />
        </div>
      )}
    </>
  );
};

export default SlideShowLinkPreview;
