import { getImageUrl } from "src/common/function";
import { linkBlockTypeArray } from "src/common/constants";

const LinkBlock = ({ type, title, Url, mainMediaImageUrl, editClicked, index }) => {
  return (
    <div className="w-full h-[106px] border-dashed border-gray-500 border-[1px] rounded-[12px] flex items-center relative bg-[#F2F6F9]">
      <div className="w-full h-[40px] px-[15px] flex items-center gap-[10px]">
        <div className="w-[50px] h-[50px] flex-shrink-0">
          {mainMediaImageUrl ? (
            <img src={mainMediaImageUrl} className="w-[50px] h-[50px] object-contain" alt="" />
          ) : (
            <img src={getImageUrl("/images/linkblock_logo.png")} className="w-[50px] h-[50px] object-contain" alt="" />
          )}
        </div>

        <div className="max-w-[calc(100%-60px)] flex flex-col relative ">
          <p className=" font-SFProTextRegular text-[14px] text-[#3F3F3F] font-[400] w-full ">{linkBlockTypeArray[type]}</p>
          <p className=" font-SFProTextRegular text-[16px] text-[#3F3F3F] font-[700] overflow-hidden whitespace-nowrap overflow-ellipsis">
            {title}
          </p>
          <p className="font-SFProTextRegular text-[14px] font-[400]  text-[#3F3F3F] break-words overflow-hidden overflow-ellipsis line-clamp-2 ">
            {Url}
          </p>
        </div>
      </div>
      <img
        src={getImageUrl("/images/edit_black.png")}
        onClick={() => editClicked(index)}
        className="w-[40px] h-[40px] absolute right-[0px] bottom-[0px] cursor-pointer"
      />

      {/* border */}
      <div className="absolute z-40 left-0 right-0 top-[-1px] rounded-tl-[12px] rounded-tr-[12px] h-[10px]" />
    </div>
  );
};

export default LinkBlock;
