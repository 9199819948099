import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { getImageUrl } from "src/common/function";
import BioContentInput from "./biocontentinput";

const BioAdditionalFields = ({ bioContents, addBioContent, headerChanged, bodyChanged, itemClicked }) => {
  return (
    <div className="w-full">
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] italic">Bio Link Fields</p>
      <div className="flex items-center gap-2 mt-2">
        <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] ">Bio Content</p>
        <div
          onClick={addBioContent}
          className="bg-[#7EA1C4] w-[16px] h-[16px] rounded-[8px] flex items-center justify-center cursor-pointer"
        >
          <img src={getImageUrl("/images/plus.png")} className="w-[80%] h-[80%] rounded-[3px]" />
        </div>
      </div>

      {bioContents.length > 0 ? (
        <div className="bg-[#D9D9D9] rounded-[4px]">
          {bioContents.map((item, index) => (
            <div key={index}>
              <div onClick={() => itemClicked(index)} className="w-full h-[38px] flex items-center relative gap-1">
                {item.isExpanded ? <IoIosArrowDown size={18} /> : <IoIosArrowForward size={18} />}
                <p className="font-SFProTextRegular text-[14px] text-[#4F4F4F] font-[700] ">{item.header}</p>
                {index < bioContents.length - 1 && !item.isExpanded && (
                  <div className="h-[1px] bg-[#4F4F4F] absolute bottom-0 left-0 right-0" />
                )}
              </div>
              {item.isExpanded && (
                <BioContentInput
                  header={item.header}
                  headerChanged={(text) => headerChanged(text, index)}
                  body={item.body}
                  bodyChanged={(text) => bodyChanged(text, index)}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="font-SFProTextRegular text-[14px] text-black font-[400] mt-2 ml-2">No Bio Content added</p>
      )}
    </div>
  );
};

export default BioAdditionalFields;
