import { useEffect, useState, useRef } from "react";
import { validImageTypes } from "src/common/constants";
import { getImageUrl } from "src/common/function";
import uploadToCloudinary from "src/common/uploadToCloudinary";

const UploadMedia = ({ label, mediaSelected, defaultImage, enabled, linkName }) => {
  const [bgColor, setBgColor] = useState("bg-white");
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setSelectedImage(defaultImage);
  }, [defaultImage]);

  useEffect(() => {
    if (enabled) {
      setBgColor("white");
    } else {
      setBgColor("#D9D9D9");
    }
  }, [enabled]);

  const uploadClick = () => {
    if (!enabled) return;
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault()

    const uploadedImgUrl = await uploadToCloudinary(e.target.files[0], linkName)
    mediaSelected(uploadedImgUrl)
    setSelectedImage(uploadedImgUrl)
  }

  return (
    <div className="w-full">
      <p className="font-SFProTextRegular text-[16px] text-[#4F4F4F] font-[700] mt-[15px] mb-[10px]">{label}</p>
      <div
        className="mt-3 flex flex-col items-center justify-center  w-[232px] h-[123px] rounded-md"
        style={{ backgroundColor: bgColor }}
      >
        {selectedImage ? (
          <img
            src={selectedImage}
            onClick={uploadClick}
            className="w-full h-full rounded-md object-contain cursor-pointer"
            alt="uploaded"
          />
        ) : (
          <div onClick={uploadClick} className="cursor-pointer flex flex-col items-center">
            <img src={getImageUrl("/images/avatar.png")} className="h-[30px]" alt="upload placeholder" />
            <p className="font-SFProTextRegular text-[12px] text-[#444A4A] mt-2">Upload image</p>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageChange}
        className="hidden"
        accept={validImageTypes.toString()}
      />
    </div>
  );
};

export default UploadMedia;
