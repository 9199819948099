import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const positionArray = ["Top", "Bottom"];
const PositionSelect = ({ positionSelected, position }) => {
  const [selectedPosition, setSelectedPosition] = useState(position);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setSelectedPosition(position);
  }, [position]);

  const dropdownClicked = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div
      onClick={dropdownClicked}
      className="w-full h-[36px] bg-white flex items-center justify-between px-2 rounded-md relative"
    >
      <p className="font-SFProTextRegular text-[14px] text-[#444A4A] font-[400]">{selectedPosition}</p>

      <FiChevronDown size={15} />
      {dropdownOpen && (
        <div className="w-full max-h-[89px] absolute top-[36px] left-0 bg-white overflow-auto rounded-sm z-30">
          {positionArray.map((item, index) => (
            <div onClick={() => positionSelected(item)} className="w-full h-[20px] px-2" key={index}>
              <p className="font-SFProTextRegular text-[13px] text-[#444A4A] font-[400] ">{item}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PositionSelect;
