import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";

import { getImageUrl, handleError } from "src/common/function";
import NormalInput from "src/pages/auth/components/normalinput";
import SignButton from "src/pages/auth/components/signbutton";
import Footer from "src/pages/auth/components/footer";
import PageSwitch from "src/pages/auth/components/pageswitch";
import AuthService from "src/services/auth.service";
import TokenService from "src/services/token.service";
import { setAuthState } from "src/redux/slices/authslice";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState(undefined);

  // const [username, setUsername] = useState("");
  // const [usernameErrorMessage, setUsernameErrorMessage] = useState(undefined);

  const [email, setEmail] = useState("");
  const [emailErrorMessge, setEmailErrorMessage] = useState(undefined);

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(undefined);

  const [isAllValid, setIsAllValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      name.length === 0 ||
      // username.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      setIsAllValid(false);
    } else {
      setIsAllValid(true);
    }
  }, [name, email, password, confirmPassword]);

  // useEffect(() => {
  //   if (usernameErrorMessage && usernameErrorMessage.length > 0) {
  //     setUsernameErrorMessage("");
  //   }
  // }, [username]);

  useEffect(() => {
    if (emailErrorMessge && emailErrorMessge.length > 0) {
      setEmailErrorMessage("");
    }
  }, [email]);

  const goToSigninPage = () => {
    navigate("/signin");
  };

  const signup = () => {
    setNameErrorMessage("");
    if (!checkPasswordValidation()) return;

    setIsLoading(true);
    AuthService.signup(name, email, password)
      .then(function (response) {
        if (response.status == 200) {
          console.log("signup success: ", response);
          TokenService.updateLocalAccessToken(response.data.accessToken);
          TokenService.setUser(response.data.user);
          dispatch(setAuthState(true));
          navigate("/");
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        // if (error.response.data.username) {
        //   setUsernameErrorMessage("Username is taken");
        // }
        if (error.response.data.email) {
          setEmailErrorMessage("Email already used");
        }
        setIsLoading(false);
      });
  };

  const checkPasswordValidation = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>.]{8,}$/;
    const isValid = passwordPattern.test(password);
    if (!isValid) {
      setIsPasswordValid(isValid);
      setPasswordErrorMessage(
        "Password must contain: <br>  &nbsp;&middot; At least 1 upper-case &nbsp;&nbsp;&nbsp;&nbsp&middot; At least 1 number <br> &nbsp;&middot; At least 1 lower-case &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&middot; At least 8 charaters <br> &nbsp;&middot; At least 1 special character"
      );
      return false;
    } else {
      setIsPasswordValid(true);
      if (password !== confirmPassword) {
        setIsConfirmPasswordValid(false);
        setPasswordErrorMessage("Password does not match");
        return false;
      } else {
        setIsConfirmPasswordValid(true);
        setPasswordErrorMessage("");
      }
    }
    return true;
  };

  return (
    <div className="w-screen h-screen pt-[50px]">
      <div className="w-full h-[80px] pb-[20px] border-b-[1px] border-gray-400">
        <div className="w-full h-[60px] flex items-center justify-center relative">
          <img src={getImageUrl("/images/main_logo.png")} className="h-[60px]" />
          <div onClick={() => navigate("/landing")} className="absolute left-[20px] bottom-[-10px]">
            <MdKeyboardBackspace size={35} color="#3F3F3F" />
          </div>
        </div>
      </div>

      <p className="font-SFProTextSemiBold text-[26px] text-darkGray text-center tracking-tighter mt-4">
        Sign up and Start Creating!
      </p>

      <div className="pl-5 mt-[30px] flex flex-col gap-1">
        <NormalInput
          type="text"
          title="Name"
          value={name}
          errorMessage={nameErrorMessage}
          placeholder="Name"
          valueChanged={(value) => setName(value)}
        />
        {/* <NormalInput
          type="text"
          title="Username"
          value={username}
          errorMessage={usernameErrorMessage}
          placeholder="Username"
          valueChanged={(value) => setUsername(value)}
        /> */}
        <NormalInput
          type="email"
          title="Email"
          value={email}
          errorMessage={emailErrorMessge}
          placeholder="Email"
          valueChanged={(value) => setEmail(value)}
        />

        <div className="w-[290px]">
          <p className="font-SFProTextSemiBold text-[16px] text-darkGray">Password</p>
          <div className={`flex items-center w-full h-[39px] rounded-[10px] bg-inputBgColor px-3 mt-2`}>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              className="text-[17px] text-inputFontColor w-full bg-transparent font-SFProTextRegular"
            />
            {isPasswordValid && <img src={getImageUrl("/images/authentication/check.png")} className="w-[25px] h-[25px]" />}
          </div>
          <div className={`flex items-center w-full h-[39px] rounded-[10px] bg-inputBgColor px-3 mt-2`}>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              placeholder="Confirm Password"
              className="text-[17px] text-inputFontColor w-full bg-transparent font-SFProTextRegular"
            />
            {isConfirmPasswordValid && (
              <img src={getImageUrl("/images/authentication/check.png")} className="w-[25px] h-[25px]" />
            )}
          </div>

          <div
            className="font-SFProTextRegular text-[14px] text-darkRedColor tracking-tighter"
            dangerouslySetInnerHTML={{ __html: passwordErrorMessage }}
          />
        </div>
      </div>

      <div className="pl-5 mt-[50px]">
        <SignButton title="Sign Up" validation={isAllValid} clicked={signup} />
        <PageSwitch clicked={goToSigninPage} title="or Sign in" />
      </div>

      <div className="absolute left-0 bottom-0 right-0">
        <Footer />
      </div>
    </div>
  );
};

export default SignUp;
