import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import AddSocialLinkButton from "../components/addsociallinkbutton";
import { bgColorArray } from "src/common/constants";
import CloseButton from "../../../components/closebutton";
import ColorSelect from "./colorselect";
import SocialLogoDropdown from "./sociallogodropdown";
import SocialLinkLogo from "src/components/sociallinklogo";
import PositionSelect from "./positionselect";
import InvalidLinkMessage from "src/components/invalidlinkmessage";
import isUrlValid from "src/common/isUrlValid";

const SocialLinkModal = ({ shown, cancelClicked, saveClicked, data, editingSocialLinkIndex, pPosition }) => {
  const [socialLinkArray, setSocialLinkArray] = useState(data);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [url, setUrl] = useState("");
  const [urlValid, setUrlValid] = useState(false);
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState(null);
  const [linkInputDisabled, setLinkInputDisabled] = useState(false);
  const [emailInputDisabled, setEmailInputDisabled] = useState(false);
  const [addButtonShown, setAddButtonShown] = useState(true);

  useEffect(() => {
    setSocialLinkArray(data);
  }, [data]);

  useEffect(() => {
    if (socialLinkArray.length === 0) {
      addSocialLink();
    }
  }, [socialLinkArray]);

  useEffect(() => {
    setSelectedIndex(editingSocialLinkIndex);
  }, [editingSocialLinkIndex]);

  useEffect(() => {
    if (pPosition) {
      setPosition(pPosition);
    } else {
      setPosition("Top");
    }
  }, [pPosition]);

  useEffect(() => {
    setUrl(socialLinkArray[selectedIndex]?.url ? socialLinkArray[selectedIndex]?.url : "");
    setEmail(socialLinkArray[selectedIndex]?.email ? socialLinkArray[selectedIndex]?.email : "");
  }, [selectedIndex, shown]);

  useEffect(() => {
    setLinkInputDisabled(false);
    setEmailInputDisabled(false);
    if (url.length > 0) {
      setLinkInputDisabled(false);
      setEmailInputDisabled(true);
      setUrlValid(isUrlValid(url));
    }
    if (email.length > 0) {
      setLinkInputDisabled(true);
      setEmailInputDisabled(false);
    }
  }, [url, email]);

  const linkChanged = (e) => {
    setUrl(e.target.value);

    const temp = [...socialLinkArray];
    temp[selectedIndex].url = e.target.value;
    setSocialLinkArray(temp);
  };

  const emailChanged = (e) => {
    setEmail(e.target.value);

    const temp = [...socialLinkArray];
    temp[selectedIndex].email = e.target.value;
    setSocialLinkArray(temp);
  };

  const colorChanged = (color) => {
    const temp = [...socialLinkArray];
    temp[selectedIndex].color = color.name;
    setSocialLinkArray(temp);
  };

  const addSocialLink = () => {
    let temp = [...socialLinkArray];
    temp.push({});
    setSocialLinkArray(temp);
    if (temp.length >= 5) {
      setAddButtonShown(false);
    }
  };

  const handleLogoSelect = (icon) => {
    const temp = [...socialLinkArray];
    temp[selectedIndex].icon = icon;
    setSocialLinkArray(temp);
  };

  const cancelHandle = () => {
    cancelClicked();
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="bg-[#F2F6F9] w-[300px] h-[610px] pb-5 rounded-md overflow-y-auto">
            <div className="w-full h-[44px] flex items-center justify-between bg-white rounded-t-md px-[20px]">
              <p className="font-SFProTextHeavy text-[20px] text-[#4F4F4F] font-[800]">{"Create > Icons"}</p>
              <CloseButton clicked={cancelClicked} />
            </div>
            <div className="px-[10px]">
              <p className="font-SFProTextRegular text-[18px] text-[#4F4F4F] font-[700] mt-[20px]">Your Social Links</p>
              <div className="h-[58px] flex items-center gap-2 overflow-x-auto overflow-y-hidden">
                {socialLinkArray.map((item, index) => (
                  <div
                    onClick={() => setSelectedIndex(index)}
                    className="w-[58px] h-[58px] relative cursor-pointer flex items-center justify-center rounded-[29px]"
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    {item.icon ? (
                      <SocialLinkLogo socialName={item.icon} />
                    ) : (
                      <div className="w-[58px] h-[58px] rounded-[29px] bg-[#7EA1C4]" style={{ backgroundColor: item.color }} />
                    )}
                    {index === selectedIndex && (
                      <div className="w-[58px] h-[58px] rounded-[29px] border-[2px] border-black absolute left-0 top-0" />
                    )}
                  </div>
                ))}
                {addButtonShown && (
                  <div className="w-[58px] h-[58px] flex items-center my-[10px]">
                    <AddSocialLinkButton clicked={addSocialLink} />
                  </div>
                )}
              </div>
            </div>
            <div className="px-[10px] border-t-[1px]">
              <p className="font-SFProTextRegular text-[18px] text-[#4F4F4F] font-[700] mt-[20px]">Add Social Link</p>
              <p className="font-SFProTextRegular text-[16px] text-black font-[600] mt-[20px]">Choose A Logo</p>
              <div className="mt-[5px] relative z-50">
                <SocialLogoDropdown
                  itemSelected={handleLogoSelect}
                  selectedLinkIndex={selectedIndex}
                  socialLinkArray={socialLinkArray}
                />
              </div>
              <p className="font-SFProTextRegular text-[16px] text-black font-[600] mt-[10px]">Link</p>
              <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
                <input value={url} onChange={linkChanged} className="w-full font-SFProTextRegular text-[16px] font-[500]" />
                {linkInputDisabled && (
                  <div className="w-full h-full absolute left-0 right-0 top-0 bottom-0 bg-gray-400 z-10 rounded-[10px]" />
                )}
              </div>
              <InvalidLinkMessage shown={!urlValid} />
              <p className="font-SFProTextRegular text-[16px] text-black font-[600] mt-[10px]">Email</p>
              <div className="w-full h-[38px] rounded-[10px] mt-[5px] border-[1px] border-gray bg-white flex items-center px-[5px] relative">
                <input value={email} onChange={emailChanged} className="w-full font-SFProTextRegular text-[18px] font-[500]" />
                {emailInputDisabled && (
                  <div className="w-full h-full absolute left-0 right-0 top-0 bottom-0 bg-gray-400 z-10 rounded-[10px]" />
                )}
              </div>
              <p className="font-SFProTextRegular text-[16px] text-black font-[600] mt-[10px] mb-[5px]">Color(Optional)</p>
              <ColorSelect
                colorSelected={colorChanged}
                color={selectedIndex ? bgColorArray.find((item) => item.name === socialLinkArray[selectedIndex].color) : null}
                colorArray={bgColorArray}
                defaultValue="Select Social Link Color"
              />
              <p className="font-SFProTextRegular text-[16px] text-black font-[600] mt-[10px] mb-[5px]">Social Link Placement</p>
              <PositionSelect position={position} positionSelected={(p) => setPosition(p)} />
              <div className="w-full h-[40px] flex gap-5 mt-6">
                <div
                  onClick={() => saveClicked(socialLinkArray, position)}
                  className="flex items-center justify-center w-[50%] rounded-md bg-darkBlueColor font-SFProTextRegular text-[14px] text-white"
                >
                  Save
                </div>
                <div
                  onClick={cancelHandle}
                  className="flex items-center justify-center w-[50%] rounded-md bg-transparent font-SFProTextRegular text-[14px] text-darkBlueColor"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default SocialLinkModal;
