import api from "./api";
import TokenService from "src/services/token.service";

const signup = (name, email, password) => {
  return api.post("/api/auth/signup", {
    name,
    email,
    password,
  });
};

const signin = (email, password) => {
  return api.post(
    "/api/auth/signin",
    {
      email,
      password,
    },
    { withCredentials: true }
  );
};

const sendForgotPasswordEmail = (email) => {
  return api.post("/api/auth/forgot_password", {
    email,
  });
};

const resetPassword = (userId, token, password) => {
  return api.post(`/api/auth/reset_password/${userId}/${token}`, {
    password,
  });
};

const logout = () => {
  TokenService.removeUser();
  TokenService.removeAccessToken();
};

const AuthService = {
  signup,
  signin,
  logout,
  sendForgotPasswordEmail,
  resetPassword,
};

export default AuthService;
