import { Route, Routes } from 'react-router-dom'
import SignIn from "src/pages/auth/signin/signin";
import SignUp from "src/pages/auth/signup/signup";
import Landing from "src/pages/landing/landing";
import ForgotPassword from "src/pages/auth/forgotpassword/forgotpassword";
import ResetPassword from "src/pages/auth/forgotpassword/resetpassword";

const UnauthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/password_reset/:userId/:token" element={<ResetPassword />} />
        </Routes> 
    )
}

export default UnauthenticatedRoutes;
