import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const ColorSelect = ({ defaultValue, colorSelected, color, colorArray }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    }
  }, [color]);

  const dropdownClicked = () => {
    console.log("dropdownOpen", dropdownOpen);
    setDropdownOpen(!dropdownOpen);
  };

  const colorClicked = (index) => {
    console.log('colorClicked', index);
    colorSelected(colorArray[index]);
  };

  return (
    <div
      onClick={dropdownClicked}
      className="w-full h-[36px] bg-white flex items-center justify-between px-2 rounded-md relative"
    >
      {selectedColor ? (
        <p className="font-SFProTextRegular text-[14px] text-[#444A4A] font-[400]">{selectedColor.name}</p>
      ) : (
        <p className="font-SFProTextRegular text-[14px] text-[#444A4A] font-[400]">{defaultValue}</p>
      )}
      <FiChevronDown size={15} />
      {dropdownOpen && (
        <div className="w-full max-h-[89px] absolute top-[36px] left-0 bg-white overflow-auto rounded-sm z-10">
          {colorArray.map((item, index) => (
            <div onClick={() => colorClicked(index)} className="w-full h-[20px] px-2" key={index}>
              <p className="font-SFProTextRegular text-[13px] text-[#444A4A] font-[400] ">{item.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorSelect;
