import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImageUrl, handleError } from "src/common/function";
import LinkService from "src/services/link.service";
import TokenService from "src/services/token.service";
import LinkItem from "./linkitem";

const Home = () => {
  const navigate = useNavigate();
  const currentUser = TokenService.getUser();
  const [links, setLinks] = useState([]);

  useEffect(() => {
    LinkService.getLinks({ userId: currentUser.id })
      .then((response) => {
        if (response.status === 200) {
          setLinks(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const linkClicked = (index) => {
    navigate(`/manage/${links[index].name}`);
  };

  return (
    <div className="w-screen h-screen pt-[70px] flex flex-col pb-[30px]">
      <img src={getImageUrl("/images/main_logo.png")} className="w-[153px] h-[64px] ml-auto mr-auto" />

      {links.length === 0 ? (
        <div className="mt-[100px] flex flex-col items-center gap-10">
          <div className="w-[90%] max-w-[350px] h-[88px] rounded-[12px] flex items-center justify-center bg-darkBlueColor cursor-pointer">
            <p className="font-roboto-mono text-[24px] text-white">Start with a default template</p>
          </div>

          <div
            onClick={() => {
              navigate("/manage");
            }}
            className="w-[90%] max-w-[350px] h-[88px] rounded-[12px] flex items-center justify-center bg-[#7EA1C4] cursor-pointer"
          >
            <p className="font-roboto-mono text-[24px] text-white">Start with a Blank Canvas</p>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-between flex-1">
          <div className="flex items-center justify-center w-full h-[70px] border-t-[1px] border-b-[1px] mt-[10px]">
            <p className="font-roboto-mono text-[24px] text-[#444A4A]">Select a Right-link</p>
          </div>
          <div className="flex-1 ">
            <div className="overflow-y-scroll" style={{ maxHeight: "calc(100vh - 364px)" }}>
              {links.map((link, index) => (
                <div key={index}>
                  <LinkItem links={links} link={link} index={index} linkClicked={linkClicked} />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full h-[150px] flex flex-col items-center justify-between py-[10px]">
            <div className="w-full h-[10px] flex items-center justify-between">
              <div className="w-[40%] h-[1px] bg-gray-200" />
              <p className="font-roboto-mono text-[13px] text-[#444A4A]">Or</p>
              <div className="w-[40%] h-[1px] bg-gray-200" />
            </div>

            <div
              onClick={() => {
                navigate("/manage");
              }}
              className="w-[90%] max-w-[350px] h-[88px] rounded-[12px] flex items-center justify-center bg-[#7EA1C4] cursor-pointer"
            >
              <p className="font-roboto-mono text-[24px] text-white">Start with a Blank Canvas</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
